<template>
  <div class="row">
    <div class="col-12">
      <media-viewer :items="media" />
    </div>
    <div class="col-12 fixed-bottom">
      <MediaToolbar
        type="modal"
        :task-id="taskId"
        :media="media"
        @updated_media="EmitMedia"
      />
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2/src/sweetalert2'
import MediaToolbar from '../../MediaToolbar'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Media',
  emits: {
    'update:modelValue': {
      type: Array
    }
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      required: false,
      default: null
    }
  },
  components: {
    MediaToolbar
  },
  data() {
    return {
      tab: 'description',
      defaultAvatar: window.defaultAvatar,
      media: [],
      description: '',
      loading: false
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.media = val
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user_id']),
    ...mapGetters('user', ['user'])
  },
  methods: {
    ...mapActions('system', ['StartLoading', 'FinishLoading']),
    EmitMedia(media) {
      this.$emit('update:modelValue', media)
    },
    OpenFile(file) {
      if (
        ['png', 'jpg', 'jpeg', 'bmp', 'pdf'].indexOf(
          file.extension.toLowerCase()
        ) >= 0
      ) {
        const url = file.file_url

        let win = window.open(url, '_blank')
        win.focus()
      } else {
        this.DownloadFile(file)
      }
    },

    DownloadFile(file) {
      this.StartLoading()

      const url = file.file_url

      window
        .axios({
          url: url,
          method: 'GET',
          responseType: 'blob'
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url

          link.setAttribute('download', file.technical_name)
          document.body.appendChild(link)
          link.click()
        })
        .finally(() => {
          this.FinishLoading()
        })
    }
  }
}
</script>

<style lang="css" scoped>
.fixed-bottom {
  bottom: 85px;
}
</style>
