<template>
  <div class="base-pagination">
    <div :class="`row ${row}`">
      <div v-for="(item, index) in filteredItems" :key="index" :class="columns">
        <slot name="body" :item="item" :index="index"></slot>
      </div>
    </div>
    <div class="row align-items-center mt-4">
      <div class="col-auto pe-1">
        <select
          class="form-select form-select-sm form-select-solid"
          v-model="itemsPerPage"
        >
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
          <option :value="100">100</option>
          <option :value="250">250</option>
        </select>
      </div>
      <div class="col text-center text-md-start align-self-center">
        <span class="counter text-muted my-2 fw-bolder">
          {{
            `Showing ${paginateSetup.initItem} to ${paginateSetup.endItem} of ${items.length} items`
          }}
        </span>
      </div>
      <div class="col-12 col-md-auto mt-2 text-center">
        <ul class="pagination">
          <li class="page-item previous">
            <a
              href="javascript:;"
              class="page-link"
              :disabled="paginateSetup.page === 1"
              @click="WalkPaginate('prev')"
            >
              <i class="fas fa-angle-left"></i>
            </a>
          </li>
          <li
            v-for="page in pagesIndex"
            :key="page"
            class="page-item"
            :class="{ active: page === paginateSetup.page }"
          >
            <template v-if="page">
              <a
                href="javascript:;"
                class="page-link"
                @click="WalkPaginate(page)"
              >
                <b>{{ page }}</b>
              </a>
            </template>
            <template v-else> <span class="valign-sub">...</span></template>
          </li>
          <li class="page-item next">
            <a
              href="javascript:;"
              class="page-link"
              :disabled="
                paginateSetup.page === paginateSetup.totalPages ||
                filteredItems.length < 1
              "
              @click="WalkPaginate('next')"
            >
              <i class="fas fa-angle-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePagination',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    row: {
      type: String,
      default: '',
    },
    columns: {
      type: String,
      default: 'col-12',
    },
  },
  computed: {
    filteredItems() {
      let numberOfPages = Math.ceil(this.items.length / this.itemsPerPage)
      let paginate = this.Paginate(
        this.items,
        this.currentPage,
        this.itemsPerPage
      )
      this.paginateSetup = paginate

      return paginate.data
    },
    pagesIndex() {
      let i = 1

      let index = []

      while (this.paginateSetup.totalPages >= i) {
        if (
          i >= this.paginateSetup.page - 3 &&
          i <= this.paginateSetup.page + 3
        ) {
          index.push(i)
        }

        i++
      }

      if (index[0] > 2) {
        index = [1, null, ...index]
      }

      if (index[index.length - 1] < this.paginateSetup.totalPages) {
        index = [...index, null, this.paginateSetup.totalPages]
      }

      return index
    },
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
      paginateSetup: {
        page: null,
        per_page: null,
        prePage: null,
        nextPage: null,
        total: null,
        totalPages: null,
        data: [],
      },
    }
  },
  methods: {
    Paginate(items, page = 1, per_page = 5) {
      page = page || 1
      per_page = per_page || 5
      let offset = (page - 1) * per_page
      let paginatedItems = items.slice(offset).slice(0, per_page)
      let totalPages = Math.ceil(items.length / per_page)

      return {
        page: page,
        per_page: per_page,
        initItem: offset + 1,
        endItem: offset + paginatedItems.length,
        prePage: page - 1 ? page - 1 : null,
        nextPage: totalPages > page ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
        data: paginatedItems,
      }
    },
    WalkPaginate(mode) {
      switch (mode) {
        case 'prev':
          this.currentPage = this.currentPage <= 1 ? 1 : this.currentPage - 1
          break
        case 'next':
          this.currentPage =
            this.currentPage < this.paginateSetup.totalPages
              ? this.currentPage + 1
              : this.currentPage - this.paginateSetup.totalPages
          break
        default:
          //direct page
          this.currentPage = mode
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
