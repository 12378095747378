<template>
	<div class="attachments">
		<div class="row justify-content-end mb-2">
			<div class="col-12 col-md-4">
				<slot name="add"></slot>
			</div>
			<div class="col-12 col-md-8">
				<div class="form-group">
					<input 
						type="search" 
						placeholder="Pesquisar" 
						class="form-control"
						v-model="search"
					>
				</div>
			</div>
		</div>
		<template v-if="filtered_items.length > 0">
			<div 
				class="row"
				v-for="item in filtered_items"
				:key="item.id"
			>
				<div class="col-12">
					<div class="card-file">
						<div class="row align-items-center">
							<div class="col-auto">
								<div class="owner">
									<div class="user">
										<img 
											:src="GetAvatar(item.user.avatar_url)"
											@error="(e) => { e.target.src = defaultAvatar }"
										>
									</div>
									<div class="icon">
										<i class="bi bi-paperclip"></i>
									</div>
								</div>
							</div>
							<div class="col">
								<p class="m-0">{{ item.name }}</p>
								<small>{{ ParseDate(item.created_at) }} - {{ item.extension }}</small>
							</div>
							<div class="col-12 col-md-auto mt-3 mt-md-0">
								<div class="d-flex justify-content-between">
									<button
										type="button"
										class="btn btn-xs btn-info mr-md-2"
										@click="OpenFile(item)"
									>
										ver
									</button>
									<button
										type="button"
										class="btn btn-xs btn-primary"
										@click="DownloadFile(item)"
									>
										download
									</button>
									<slot name="buttons" :item="item"></slot>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<p class="counter">{{ filtered_items.length }} arquivo(s)</p>
				</div>
			</div>
		</template>
		<template v-else>
			<template v-if="items.length > 0">
				<div class="row">
					<div class="col-12">
						<div class="alert alert-warning m-0">
							Sua pesquisa por "{{ search }}" retornou sem resultados.
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="row">
					<div class="col-12">
						<div class="alert alert-info m-0">
							Não há arquivos para exibir
						</div>
					</div>
				</div>
			</template>		
		</template>		
	</div>
</template>

<script>
	import Swal from 'sweetalert2/src/sweetalert2'
	import { mapActions, mapGetters } from 'vuex'
	export default {

		name: 'Attachments',
		props: {
			items: 	{
				type: Array,
				default: () => []
			}
		},
		data () {
			return {
				search: 		'',
				defaultAvatar: 	window.defaultAvatar
			}
		},
		computed: {
			filtered_items()
			{
				const search 		= window.helpers.RemoveAccent(this.search.toString().toLowerCase())

				return this.items.filter( item => {
					if(search)
					{
						let match 		= false

						const indexes 	= [
							'created_at',
							'extension',
							'technical_name',
							'updated_at',
							'admin.name',
							'task_id',
							'name',
							'id'
						]

						for (const index of indexes) 
						{
							let i 	= window._.get(item, index)

							if(i)
							{
								i 		= window.helpers.RemoveAccent(i.toString().toLowerCase())

								if(i.indexOf(search) > -1)
								{
									match 		= true

									break
								}
							}


						}

						return match
					}

					return true

				})
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			ParseDate(date)
			{
				return window.helpers.ParseDate(date)
			},
			GetAvatar(avatar_url)
			{	
				let url 		= this.defaultAvatar

				if(avatar_url)
				{
					url 			= avatar_url
				}

				return url

			},
			OpenFile(file)
			{
				if(file.extension && ['png','jpg','jpeg','bmp','pdf'].indexOf(file.extension.toLowerCase()) >= 0)
				{
					const url 	= file.file_url

					let win 	= window.open(url,'_blank')
					win.focus()

				}else{

					this.DownloadFile(file)
				}
			},
			DownloadFile(file)
			{
				this.StartLoading()

				let mime 	= null

				switch(file.extension)
				{
					case 'ogg':
					case 'weba':
					case 'mp3':
						mime 		= `audio/${file.type}`
					break
					case 'wav':
						mime 		= `audio/x-wav`
					break
					case 'png':
					case 'jpeg':
					case 'jpg':
					case 'webp':
						mime 		= `image/${file.type}`
					break
					case 'zip':
					case 'pdf':
					case 'xml':
						mime 		= `application/${file.type}`
					break
					case '7z':
						mime 		= 'application/x-7z-compressed'
					break
					case 'rar':
						mime 		= 'application/x-rar-compressed'
					break
					case 'xlsx':
					case 'xls':
						mime 		= `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,`
					break
					case 'ppt':
						mime 		= 'application/vnd.ms-powerpoint'
					break
					case 'doc':
					case 'docx':
						mime 		= 'application/msword'
					break
					default:
						mime 		= 'application/octet-stream'
					break
				}

				const url 	= file.file_url

				window.axios({
				  	url: url,
				  	method: 'GET',
				  	responseType: 'blob',
				}).then((response) => {
				   	const url 		= window.URL.createObjectURL(new Blob([response.data], { type: mime }));
				   	const link 		= document.createElement('a');
				   	link.href 		= url;

				   	link.setAttribute('download', file.technical_name);
				   	document.body.appendChild(link);
				   	link.click();
				})
				.finally(() => {

					this.FinishLoading()

				})
			},
		}
	}
</script>

<style lang="css" scoped>
	.attachments .card-file
	{
		background-color: #ccc;
		border: 1px solid #d8d8d8;
		color: #000;
		border-radius: 4px;
		padding: .5rem;
		margin-bottom: .5rem;
		transition: all .15s linear;
	}

	.attachments .card-file:hover
	{
		background-color: #7e8285 !important;
		color: #eee !important;
	}

	.attachments .card-file p
	{
		font-weight: 700;
	    text-transform: uppercase;
	    font-size: .865rem;
	}

	.attachments p.counter
	{
		text-transform: uppercase;
	    font-weight: 700;
	    font-size: .8rem;
	    margin-top: 5px;
	    text-align: right;
	}
</style>