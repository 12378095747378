import Internal from '@layouts/Internal'

export default [
  {
    path: '/finances',
    component: () => import('@layouts/components/EmptyComponent'),
    meta: {
      onMenu: true,
      onBreadcrumb: true,
      title: 'Finances',
    },
    children: [
      {
        name: 'ProductList',
        path: 'product/list',
        component: () => import('@modules/finances/views/ProductList'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          title: 'All products',
          onMenu: true,
          layout: Internal,
        },
      },
      {
        name: 'ProductCreate',
        path: 'product/new',
        component: () => import('@modules/finances/views/ProductCreate'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          title: 'Product Create',
          onMenu: true,
          layout: Internal,
        },
      },
      {
        name: 'ProductDetails',
        path: 'product/:product_id/details',
        props: true,
        component: () => import('@modules/finances/views/ProductDetails'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          title: 'Product Details',
          layout: Internal,
          alternativeMenu: 'ProductList',
        },
      },
      {
        name: 'UsageList',
        path: 'usage/list',
        props: true,
        component: () => import('@modules/finances/views/UsageList'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          onMenu: true,
          title: 'Usages',
          layout: Internal,
          alternativeMenu: 'UsageList',
        },
      },
      {
        name: 'UsageDetails',
        path: 'usage/:usage_id/details',
        props: true,
        component: () => import('@modules/finances/views/UsageDetails'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          onMenu: false,
          title: 'Usage details',
          layout: Internal,
          alternativeMenu: 'UsageList',
        },
      },
      {
        name: 'InvoiceList',
        path: 'invoice/list',
        props: true,
        component: () => import('@modules/finances/views/InvoiceList'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          onMenu: true,
          title: 'Invoices',
          layout: Internal,
          alternativeMenu: 'InvoiceList',
        },
      },
      {
        name: 'InvoiceDetails',
        path: 'invoice/:invoice_token/details',
        props: true,
        component: () => import('@modules/finances/views/InvoiceDetails'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          onMenu: false,
          title: 'Invoice details',
          layout: Internal,
          alternativeMenu: 'InvoiceList',
        },
      },
      {
        path: '',
        redirect: {
          name: 'ProductList',
        },
      },
    ],
  },
]
