<template>
  <div
    class="modal fade"
    id="notification-modal"
    tabindex="-1"
    aria-labelledby="notification-modal"
    aria-hidden="true"
    aria-modal="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <div class="form-row flex-nowrap align-items-center w-100">
            <div class="col">
              <div class="modal-title">
                Notificações
              </div>
            </div>
            <div class="col-auto">
              <a
                href="javascript:;"
                class="text-dark"
                aria-label="Fechar"
                data-dismiss="modal"
              >
                <i class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>
        <div class="modal-body px-0">
          <div class="notification-all">
            <ul class="m-0" v-if="notifications.length > 0">
              <li
                v-for="(notification, index) in filtered_notifications"
                :key="index"
              >
                <div class="form-row flex-nowrap">
                  <div class="col-3 col-sm-2 align-self-center">
                    <div class="form-row">
                      <div class="col-12 text-center">
                        <div :class="`icon text-${notification.content.color}`">
                          <i class="material-icons status">{{
                            notification.content.icon
                          }}</i>
                        </div>
                      </div>
                      <div class="col-12 text-center">
                        <div class="date">
                          {{ notification.content.date }} <br />
                          {{ notification.content.time }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col-12">
                        <div class="title">
                          {{ notification.content.title }}
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="text">
                          {{ notification.content.text }}
                          <template v-if="notification.content.action">
                            <template
                              v-if="
                                notification.content.action.type ==
                                  'router-push'
                              "
                            >
                              <router-link
                                :to="{
                                  name: notification.content.action.destiny
                                }"
                              >
                                {{ notification.content.action.text }}
                              </router-link>
                            </template>
                            <template
                              v-else-if="
                                notification.content.action.type == 'decision'
                              "
                            >
                              <br />
                              <div
                                class="form-row flex-nowrap align-items-center mt-3"
                              >
                                <div class="col-6">
                                  <button
                                    type="button"
                                    class="btn btn-xs btn-danger"
                                    @click="TakeDecision(false, notification)"
                                  >
                                    Cancelar
                                  </button>
                                </div>
                                <div class="col-6 text-right">
                                  <button
                                    type="button"
                                    class="btn btn-xs btn-success"
                                    @click="TakeDecision(true, notification)"
                                  >
                                    Confirmar
                                  </button>
                                </div>
                              </div>
                            </template>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-auto">
                    <a
                      href="javascript:;"
                      class="btn close"
                      :class="{
                        'disabled text-dark': loading,
                        'text-danger': !loading
                      }"
                      v-if="
                        !loading &&
                          notification.content.action.type != 'decision'
                      "
                      @click="HideNotification(notification)"
                    >
                      <i
                        class="material-icons"
                        :class="{
                          'fa-spin': loading
                        }"
                      >
                        {{ loading ? 'loop' : 'close' }}
                      </i>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <ul v-else class="m-0">
              <li class="p-0">
                <div class="alert mb-0 alert-info">
                  Não há notificações para exibir
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Notifications',
  data() {
    return {
      defaultAvatar: window.defaultAvatar,
      loading: false
    }
  },
  computed: {
    ...mapGetters('notification', ['notifications', 'schedule_notifications']),
    filtered_notifications() {
      return this.notifications.filter((n) => n.content)
    }
  },
  methods: {
    ...mapActions('notification', [
      'DeleteNotification',
      'MakeDecisionOnNotification'
    ]),
    ExecAction(command) {},
    TakeDecision(decision, notification) {
      this.loading = true

      this.MakeDecisionOnNotification({
        id: notification.id,
        decision: decision
      }).finally(() => {
        this.loading = false
      })
    },
    HideNotification(notification) {
      this.loading = true

      this.DeleteNotification({
        id: notification.id
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="css" scoped></style>
