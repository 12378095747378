<template>
	<div class="form-row flex-nowrap">
		<div class="col">
			<audio :src="src" controls="true"></audio>
		</div>
	</div>
</template>

<script>
	export default {

		name: 'AudioPlayer',
		props: {
			src: 	{
				type: 		String,
				required: 	true
			}
		},
		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>
</style>