import Vuex from '@store'
import NProgress from 'nprogress'

NProgress.configure({
  showSpinner: false,
})

export default (to, from, next) => {
  const allowed = to.meta.only_type
    ? Vuex.getters['auth/frame_type'] == to.meta.only_type
    : true

  if (allowed) {
    if (to.matched.some((record) => record.meta.middlewareAuth)) {
      NProgress.start()

      if (!Vuex.getters['auth/check']) {
        next({
          name: 'AuthLogin',
          query: { redirect: to.fullPath },
        })

        return
      }
    }

    next()
  } else {
    window.history.go(-1)
  }
}
