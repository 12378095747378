import api from '@utils/api'

const GetAdmin = ({ commit, dispatch }, payload) => {
  dispatch('system/StartLoading', null, {
    root: true
  })

  return api
    .call('get', '/get-my-self')
    .then(({ data }) => {
      if (data.response) {
        commit('UPDATE_ADMIN', data.admin)
      }
    })
    .finally(() => {
      dispatch('system/FinishLoading', null, {
        root: true
      })
    })
}

export { GetAdmin }
