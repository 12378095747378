const ADD 	= (state) => {
	state.requestLoading++
}

const REM 	= (state) => {
	state.requestLoading > 0 ? state.requestLoading-- : 0
}

export {
	ADD,
	REM
}