<template>
	<div class="row">
		<div v-for="(field, index) in fields" :key="index" class="col-12">
				<div class="form-row flex-nowrap align-items-center">
					<div class="col">
						<div class="form-row">
							<div class="col-12 col-md-4">
								<input 
									type="text"
									class="form-control"
									placeholder="Label"
									v-model="field.label"
								>
							</div>
							<div class="col-12 col-md-8">
								<input 
									type="text"
									class="form-control"
									placeholder="Conteudo" 
									v-model="field.content"
								>
							</div>
						</div>
					</div>
					<div class="col-auto">
						<button 
							type="button" 
							class="btn btn-danger btn-icon"
							@click="RemField(index)"
						>
							<i class="material-icons">close</i>
						</button>
					</div>
				</div>
				<div class="row" v-if="(index + 1) != fields.length">
					<div class="col-12">
						<hr/>
					</div>
				</div>
		</div>
		<div 
			class="col-12"
			:class="{
				'mt-4': fields.length > 0
			}"
		>
			<a 
				href="javascript:;" 
				class="anchor-link"
				@click="AddField"
			>
				+ Adicionar novo campo
			</a>
		</div>
	</div>
</template>

<script>
	export default {

		name: 'CustomFields',
		emits: {
			'update:modelValue': {
				type: Object
			}
		},
		props: 	{
			modelValue: 	{
				type: 			Array,
				required: 		false,
				default: 		() => []
			}
		},
		data () {
			return {
				fields: 		[]
			}
		},
		watch: 	{
			modelValue: {
				immediate: true,
				handler()
				{
					this.fields 		= [...this.modelValue]
				},
				deep: true
			}
		},
		methods: {
			AddField()
			{
				this.fields.push({
					label: 		'',
					content: 	''
				})
			},
			RemField(index)
			{
				this.fields.splice(index, 1)
			},
			Submit()
			{
				this.$emit('update:modelValue', this.fields)
			}
		}
	}
</script>

<style lang="css" scoped>
</style>