<template>
	<div class="media-toolbar">
		<div class="creation-toolbar">
			<ul 
				class="nav nav-pills" 
				:id="`${type}_creation-toolbar-tabs`"
				role="tablist"
			>
				<li class="nav-item" role="presentation">
					<a 
						class="nav-link active"
						data-toggle="tab" 
						:href="`#${type}_description-tab`" 
						role="tab"
						:aria-controls="`${type}_description-tab`" 
						aria-selected="true"
					>
						<i class="material-icons">comment</i>
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a 
						class="nav-link"
						data-toggle="tab" 
						:href="`#${type}_files-tab`" 
						role="tab"
						:aria-controls="`${type}_files-tab`" 
						aria-selected="false"
					>
						<i class="material-icons">attach_file</i>
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a 
						class="nav-link"
						data-toggle="tab" 
						:href="`#${type}_audio-tab`" 
						role="tab"
						:aria-controls="`${type}_audio-tab`" 
						aria-selected="false"
					>
						<i class="material-icons">mic</i>
					</a>
				</li>
			</ul>
			<div class="tab-content" :id="`${type}_creation-toolbar-tab-contents`">
				<div 
					class="tab-pane fade show active"
					role="tabpanel" 
					:id="`${type}_description-tab`"
					:aria-labelledby="`${type}_description-tab`"
				>
					<div class="py-3">
						<div class="row align-items-center">
							<div class="col-12 col-md">
							  	<textarea
							  		v-model="description"
							  		@keyup.enter="SaveDescription"
							  		@paste="GetImageFromClipboard"
							  		class="form-control"
							  		rows="3"
							  	>										  		
							  	</textarea>
								<div class="float-send">
									<button 
										type="button" 
										class="btn btn-primary send-btn"
										@click="SaveDescription"
									>
										<i class="material-icons">send</i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div 
					class="tab-pane fade"
					role="tabpanel" 
					:id="`${type}_files-tab`"
					:aria-labelledby="`${type}_files-tab`"
				>
					<div class="py-3">
						<div class="form-row">
							<div class="col-12">
								<div class="file-drop-area my-2">
									<input class="file-input" type="file" multiple @change="UploadFile($event)"/>
									<div class="file-message">
										<span >Selecione ou arraste o arquivo aqui</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div 
					class="tab-pane fade"
					role="tabpanel" 
					:id="`${type}_audio-tab`"
					:aria-labelledby="`${type}_audio-tab`"
				>
					<div class="py-3">
						<audio-recorder
							@send="SaveAudio"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Swal from 'sweetalert2/src/sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'MediaToolbar',
		emits: {
			updated_media: 	{
				type: Array
			}
		},
		props: 	{
			type: 	{
				type: 		String,
				default: 	'playlist'
			},
			taskId: {
				type:  		[String, Number],
				default: 	null
			},
			media: {
				type:  		Array,
				default: 	() => []
			}
		},
		data () {
			return {
				defaultAvatar: 		window.defaultAvatar,
				description: 		'',
				items: 				[],

			}
		},
		computed: {
			...mapGetters('auth', [
				'user_id'
			]),
			...mapGetters('user', [
				'user'
			]),
		},
		watch: {
			media: {
				handler(val)
				{
					this.items  	= val
				},
				deep: true,
				immediate: true
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('task', [
				'RefreshCurrentTask'
			]),
			async GetImageFromClipboard(e)
			{
				let files			= []
				let items 			= e.clipboardData.items

				for (let i = 0; i < items.length; i++) 
				{
   				    if (items[i].type.indexOf("image") == -1)
   				    {
				    	continue
				    } 

				    let blob = items[i].getAsFile()
				    
			    	files.push(blob)
				}

				if(files.length > 0)
				{
					await this.UploadCore(files)

					this.description 	= ''
				}
			},
			UploadFile(event)
			{
				const files 		= event.target.files

				this.UploadCore(files)
			},
			UploadCore(files)
			{	
				this.StartLoading()

				const formData 		= new FormData()
				
				for (let i = 0; i < files.length; i++) 
				{
					formData.append('file[' + i + ']',files[i],files[i].name)
				}	

				if(this.taskId)
				{
					formData.append('source', 'update-task')
					formData.append('task_id', this.taskId)

				}else{

					formData.append('source', 'new-task')
				}

				formData.append('type', 2) //FILE

		  		return window.api.call('post','/save-media',formData)
					.then(({data}) => {

						this.FinishLoading()

						if(data.response)
						{	
							this.items 		= this.items.concat(data.files)

							switch(this.type)
							{
								case 'playlist':

									this.RefreshCurrentTask({
										item: 'media', 
										data: this.items
									})

								break

								case 'modal':
									this.$emit('updated_media', this.items)
								break
							} 

							Swal.fire({
								toast: 					true,
								position: 				'top',
								timerProgressBar: 		true,
								icon: 					'success',
								title: 					'Arquivos adicionados com sucesso.',
								showConfirmButton: 		false,
								timer: 					2000
							})

						}else{

							Swal.fire({
								icon: 				'error',
								title: 				'Houve um erro ao adicionar, contate o suporte.',
								showConfirmButton: 	true
							})
						}
					})
			},
			SaveDescription()
			{
				if(this.description)
				{
					this.StartLoading()

					let obj 		= {
						text: 			this.description,
						type: 			1 //DESCRIPTION
					}

					if(this.taskId)
					{
						obj.source 		= 'update-task'
						obj.task_id 	= this.taskId

					}else{

						obj.source 		= 'new-task'
					}

					return window.api.call('post','/save-media', obj)
						.then(({data}) => {
							if(data.response)
							{	
								this.items.push(data.description)

								switch(this.type)
								{
									case 'playlist':

										this.RefreshCurrentTask({
											item: 'media', 
											data: this.items
										})

									break

									case 'modal':
										this.$emit('updated_media', this.items)
									break
								} 

								this.description 	= ''

							}else{

								Swal.fire({
									title:  'Opss...',
									text: 	'Houve um erro ao salvar, contate o suporte.',
									icon: 	'error'
								})
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				}
			},
			SaveAudio(e)
			{
				this.StartLoading()

				const formData 		= new FormData()
				const audio 		= e.blob

				formData.append('audio', audio)
				formData.append('type', 3) //FILE
				formData.append('source', 'update-task');
				formData.append('task_id', this.current.id);

		  		return window.api.call('post','/save-media', formData)
					.then(({data}) => {

						this.FinishLoading()

						if(data.response)
						{	
							this.items 		= this.items.concat(data.audio)

							switch(this.type)
							{
								case 'playlist':

									this.RefreshCurrentTask({
										item: 'media', 
										data: this.items
									})

								break

								case 'modal':
									this.$emit('updated_media', this.items)
								break
							} 

							Swal.fire({
								toast: 					true,
								position: 				'top',
								timerProgressBar: 		true,
								icon: 					'success',
								title: 					'Audio adicionado com sucesso.',
								showConfirmButton: 		false,
								timer: 					2000
							})

						}else{

							Swal.fire({
								icon: 				'error',
								title: 				'Houve um erro ao adicionar, contate o suporte.',
								showConfirmButton: 	true
							})
						}
					})
			},
			DeleteMedia(file)
			{
				this.StartLoading()

				return window.api.call('delete','/delete-media/' + file.id)
					.then(({data}) => {

						this.FinishLoading()

						if(data.response)
						{
							let index 	= this.items.findIndex( media => media.id === file.id)
							
							this.items.splice(index, 1, data.media)

							switch(this.type)
							{
								case 'playlist':

									this.RefreshCurrentTask({
										item: 'media', 
										data: this.items
									})

								break

								case 'modal':
									this.$emit('updated_media', this.items)
								break
							} 

							Swal.fire({
								toast: 					true,
								position: 				'top',
								timerProgressBar: 		true,
								icon: 					'success',
								title: 					'Arquivo deletado com sucesso.',
								showConfirmButton: 		false,
								timer: 					2000
							})

						}else{

							Swal.fire({
								icon: 				'error',
								title: 				'Houve um erro ao deletar, contate o suporte.',
								showConfirmButton: 	true
							})
						}
					})
				
			},
			ParseDate(date)
			{
				return window.helpers.ParseDate(date)
			}
		}
	}
</script>

<style lang="css" scoped>
</style>