const user_id 	 = (state) => {
	return state.user_id
}

const token 	 = (state) => {
	return state.token
}

const frame_type = (state) => {
	return state.frame_type
}

const check 	 = (state) => {
	return !! state.token;
}

export {
	user_id,
	token,
	check,
	frame_type
}