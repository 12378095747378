<template>
  <div class="autocomplete">
    <div class="mb-3">
      <label class="fs-6 form-label fw-bolder text-dark" v-if="label">
        {{ label }}
      </label>
      <div class="input-group">
        <input
          type="text"
          class="form-control form-control-solid"
          :class="{ shadow: isOpen, 'is-invalid': validate.bool }"
          :placeholder="placeholder"
          @input="OnChange"
          @focus="isOpen = true && search.toString().length"
          v-model="search"
          @keydown.down="OnArrowDown"
          @keydown.up="OnArrowUp"
          @keydown.enter="OnEnter"
          :disabled="disabled"
        />
        <button
          class="btn btn-icon"
          :class="{
            'btn-danger': !IsEmpty(selected),
            'btn-secondary': IsEmpty(selected),
          }"
          type="button"
          style="
            border-top-right-radius: 0.95rem;
            border-bottom-right-radius: 0.95rem;
          "
          :disabled="IsEmpty(selected) || disabled"
          @click="ClearSelected"
        >
          <i class="fas fa-times"></i>
        </button>
        <div class="invalid-feedback">
          {{ validate.text }}
        </div>
      </div>

      <transition-group
        name="fast-fade"
        tag="ul"
        class="autocomplete-results shadow"
        v-show="isOpen"
      >
        <li class="autocomplete-result" v-if="isLoading" key="loading">
          Carregando...
        </li>
        <li
          v-else-if="results.length < 1"
          class="autocomplete-result"
          @click="CloseResults"
          key="nodata"
        >
          <span> Não há resultados para "{{ search }}" </span>
        </li>
        <li
          v-else
          class="autocomplete-result"
          v-for="(result, index) in results"
          :key="itemValue ? result[itemValue] : result"
          :class="{ 'is-active': index === arrowCounter }"
          @click="SetResult(result)"
        >
          <slot name="item" :item="result">
            {{ itemText ? result[itemText] : result }}
          </slot>
        </li>
        <li
          class="autocomplete-result bg-warning"
          v-if="!isLoading && allowCreate"
          key="new_item"
          @click="CreateNew"
        >
          <span>
            <div class="form-row flex-nowrap align-items-center">
              <div class="col-auto">
                <div class="badge badge-success">
                  <i class="material-icons" style="vertical-align: middle"
                    >add</i
                  >
                </div>
              </div>
              <div class="col">
                <strong>Clique aqui</strong> para criar "{{ search }}"
              </div>
            </div>
          </span>
        </li>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAutocomplete',
  emits: {
    'update:modelValue': {
      type: String,
    },
    'create-command': {
      type: String,
    },
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemValue: {
      type: String,
      required: false,
      default: null,
    },
    itemText: {
      type: String,
      required: false,
      default: null,
    },
    itemType: {
      type: String,
      required: false,
      default: 'object',
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
    validate: {
      type: Object,
      required: false,
      default: () => ({
        bool: false,
        text: '',
      }),
    },
    CustomFilter: {
      type: Function,
      required: false,
      default: () => {},
    },
    modelValue: {
      type: [Object, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      selected: {},
      results: [],
      isOpen: false,
      isLoading: false,
      arrowCounter: -1,
    }
  },
  computed: {},
  watch: {
    items(value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value
        //this.isOpen 			= true
        this.isLoading = false
      }
    },
    modelValue: {
      immediate: true,
      handler() {
        this.FindObj()
      },
      deep: true,
    },
    search(val) {
      if (!val.toString().length) {
        this.isOpen = false
      }
    },
  },
  methods: {
    OnChange() {
      if (typeof this.search === 'object') {
        this.selected = this.search

        this.$emit('update:modelValue', this.selected)
      }

      if (this.isAsync) {
        this.isLoading = true
      } else {
        this.FilterResults()
        this.isOpen = true
      }
    },
    FilterResults() {
      if (typeof this.CustomFilter !== 'undefined') {
        this.results = this.items.filter((item) =>
          this.CustomFilter(item, this.search)
        )
      } else {
        this.results = this.items.filter((item) => {
          let result = this.itemText ? item[this.itemText] : item

          return result.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        })
      }
    },
    SetResult(result) {
      this.selected = result

      this.$emit('update:modelValue', this.selected)

      this.search = this.itemText ? this.selected[this.itemText] : this.selected
      this.isOpen = false
    },
    OnArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    OnArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    OnEnter() {
      this.selected = this.results[this.arrowCounter]

      if (!window._.isEmpty(this.selected)) {
        this.$emit('update:modelValue', this.selected)

        this.search = this.itemText
          ? this.selected[this.itemText]
          : this.selected

        this.isOpen = false

        this.arrowCounter = -1
      }
    },
    CloseResults() {
      this.isOpen = false
    },
    CreateNew() {
      if (window._.isEmpty(this.selected)) {
        this.$emit('create-command', this.search)
      }

      this.isOpen = false
    },
    HandleClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    },
    FindObj() {
      if (this.modelValue) {
        let value = this.itemValue
          ? this.modelValue[this.itemValue]
          : this.modelValue

        if (value) {
          let find = this.items.find(
            (item) => (this.itemValue ? item[this.itemValue] : item) == value
          )

          if (find) {
            this.selected = find
            this.$emit('update:modelValue', this.selected)

            this.search = this.itemText ? find[this.itemText] : find
            this.isOpen = false
            this.arrowCounter = -1
          } else {
            this.selected = {}
            this.search = ''
            this.arrowCounter = -1
            this.isOpen = false
            //this.ClearSelected()
          }
        } else {
          this.selected = {}
          this.search = ''
          this.arrowCounter = -1
          this.isOpen = false
          //this.ClearSelected()
        }
      } else {
        this.selected = {}
        this.search = ''
        this.arrowCounter = -1
        this.isOpen = false
        //this.ClearSelected()
      }
    },
    ClearSelected() {
      switch (this.itemType) {
        case 'string':
          this.selected = ''
          break

        case 'object':
          this.selected = {}
          break
      }

      this.search = ''
      this.arrowCounter = -1
      this.isOpen = false

      this.$emit('update:modelValue', this.selected)
    },
    IsEmpty(obj) {
      switch (this.itemType) {
        case 'string':
          return typeof obj !== 'string'

        case 'object':
          return typeof obj !== 'object' || window._.isEmpty(obj)
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.HandleClickOutside)

    if (this.modelValue) {
      this.FindObj()
    }
  },
  unmounted() {
    document.removeEventListener('click', this.HandleClickOutside)
  },
  beforeMount() {},
}
</script>

<style lang="css" scoped></style>
