<template>
  <div>
    <div
      class="modal fade"
      id="task-forward-model"
      tabindex="-1"
      aria-labelledby="task-forward-model"
      aria-hidden="true"
      aria-modal="true"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <div class="form-row flex-nowrap align-items-center w-100">
              <div class="col">
                <div class="modal-title">
                  Encaminhar atividade
                </div>
              </div>
              <div class="col-auto">
                <a
                  href="javascript:;"
                  class="text-dark"
                  aria-label="Fechar"
                  data-dismiss="modal"
                >
                  <i class="bi bi-x-lg"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="form-row">
              <div class="col-12">
                <autocomplete
                  label="Adicione usuários"
                  :items="filtered_users"
                  item-text="name"
                  item-value="id"
                  :custom-filter="UsersFilter"
                  placeholder="Informe um usuário"
                  :validate="{
                    bool: v$.forward.users.$error,
                    text: 'Obrigatório'
                  }"
                  v-model="selected_user"
                >
                  <template #item="{ item }">
                    <div class="chip">
                      <div class="avatar">
                        <img
                          :src="GetAvatar(item.avatar_url)"
                          @error="
                            (e) => {
                              e.target.src = defaultAvatar
                            }
                          "
                        />
                      </div>
                      <div class="name">
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </autocomplete>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <div class="row">
                    <div class="col-12">
                      <transition name="fade" mode="out-in">
                        <div
                          class="row"
                          v-if="forward && forward.users.length > 0"
                          key="1"
                        >
                          <div class="col-12">
                            <label>Selecionados</label>
                          </div>
                          <div class="col-12">
                            <transition-group
                              tag="div"
                              class="form-row"
                              name="fade"
                              mode="out-in"
                            >
                              <div
                                class="col-auto"
                                v-for="user in forward.users"
                                :key="user.id"
                              >
                                <div class="overlay-function mb-2">
                                  <div class="card bg-secondary">
                                    <div class="px-2 py-1">
                                      <div
                                        class="form-row flex-nowrap align-items-center"
                                      >
                                        <div class="col-auto">
                                          <img
                                            class="img-avatar"
                                            :src="GetAvatar(user.avatar_url)"
                                            @error="
                                              (e) => {
                                                e.target.src = defaultAvatar
                                              }
                                            "
                                          />
                                        </div>
                                        <div class="col">
                                          <p class="m-0">
                                            {{ user.name }}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        class="overlay-action"
                                        @click="RemoveSelectedUser(user)"
                                      >
                                        Remover
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </transition-group>
                          </div>
                        </div>
                        <div class="row" v-else key="2">
                          <div class="col-12">
                            <div class="alert alert-info">
                              Não há usuários selecionados
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-12 text-right">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="ForwardTaskContainer"
                >
                  Encaminhar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2/src/sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'Forward',
  emits: {
    close: {
      type: Boolean
    }
  },
  props: {
    content: {
      type: Object,
      default: null
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      forward: {
        users: []
      },
      selected_user: {},
      defaultAvatar: window.defaultAvatar
    }
  },
  validations() {
    return {
      forward: {
        users: {
          required
        }
      }
    }
  },
  watch: {
    selected_user(val) {
      if (!window._.isEmpty(val)) {
        this.forward.users.push(val)

        this.selected_user = {}
      }
    }
  },
  computed: {
    ...mapGetters('task', [
      'modal_open',
      'modal_form_type',
      'modal_mode',
      'modal_task_id'
    ]),
    ...mapGetters('user', ['users']),
    filtered_users() {
      const users = this.users.filter((user) => {
        return this.forward && this.forward.users
          ? !this.forward.users.find((u) => u.id === user.id)
          : true
      })

      return users
    }
  },
  methods: {
    ...mapActions('system', ['StartLoading', 'FinishLoading']),
    ...mapActions('task', ['CloseModal', 'GetTasks']),
    ForwardTaskContainer() {
      this.v$.forward.$touch()

      if (!this.v$.forward.$invalid) {
        this.StartLoading()

        return window.api
          .call('put', `/tasks/${this.content.task.id}/forward`, {
            users: this.forward.users
          })
          .then(() => {
              Swal.fire({
                toast: true,
                position: 'top',
                timerProgressBar: true,
                icon: 'success',
                title: 'Atividade encaminhada com sucesso !',
                showConfirmButton: false,
                timer: 3000
              })
   
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Opss...',
              text: 'Houve um erro ao concluir, contate o suporte.'
            })
          })
          .finally(() => {
            this.CloseModal()
            this.forward.users = []

            this.FinishLoading()
          })
      }
    },
    UsersFilter(item, queryText) {
      const searchText = queryText.toLowerCase()

      let arrSearch = ['name', 'email', 'description']

      let resultSearch = arrSearch
        .map((param) =>
          item[param]
            ? window.helpers
                .RemoveAcento(item[param].toString().toLowerCase())
                .indexOf(searchText) > -1
            : false
        )
        .filter((param) => param)

      return resultSearch.length > 0
    },
    RemoveSelectedUser(user) {
      this.forward.users = this.forward.users.filter(
        (item) => item.id != user.id
      )
    },
    GetAvatar(avatar_url) {
      let url = window.defaultAvatar

      if (avatar_url) {
        url = avatar_url
      }

      return url
    },
    ClearForm() {
      this.forward.users = []
      this.selected_user = {}
    }
  }
}
</script>

<style></style>
