const context = require.context('.', false, /(The|Base)[\w-]+\.vue$/)

const register = (app) => {
  context.keys().forEach((fileName) => {
    if (fileName === './index.js') return

    const componentConfig = context(fileName)
    const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')

    app.component(componentName, componentConfig.default || componentConfig)
  })
}

export default {
  register,
}
