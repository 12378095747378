<template>
  <transition name="fast-fade" mode="out-in">
    <div class="page-loading" v-if="loading">
      <div class="page-loader flex-column">
        <div class="d-flex align-items-center mt-5">
          <span class="spinner-border text-primary" role="status"></span>
          <span class="text-muted fs-6 fw-bold ms-5">
            Carregando
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'BaseLoader',
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('system', ['requests']),
  },
  watch: {
    requests(val) {
      this.loading = val > 0
    },
  },
}
</script>

<style lang="scss" scoped></style>
