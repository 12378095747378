<template>
    <div class="internal-container d-flex flex-column flex-root">
        <div class="d-flex flex-row flex-column-fluid">
            <div class="aside">
                <div class="aside-menu flex-column-fluid">
                    <div class="hover-scroll-overlay-y px-2 my-5 my-lg-5">
                        <Menu />
                    </div>
                </div>
                <div class="aside-footer flex-column-auto pb-5">
                    <UserModal />
                </div>
            </div>
            <div class="wrapper d-flex flex-column flex-row-fluid">
                <div class="header align-items-stretch">
                    <div class="header-brand">
                        <router-link :to="{ name: 'DashboardIndex' }">
                            <img :src="logo" title="Olie" />
                            <span class="logo-subtitle">ERP</span>
                        </router-link>
                        <div class="d-flex align-items-center d-lg-none ms-n3 me-1">
                            <div class="btn btn-icon btn-active-color-primary w-30px h-30px">
                                <i class="fas fa-bars"></i>
                            </div>
                        </div>
                    </div>
                    <div class="topbar">
                        <div
                            class="container-fluid py-6 py-lg-0 d-flex flex-column flex-sm-row align-items-lg-stretch justify-content-sm-between"
                        >
                            <Breadcrumb />
                            <div class="d-flex align-items-center pt-3 pt-sm-0">
                                <div class="d-flex">
                                    <div class="d-flex align-items-center me-4">
                                        <a
                                            href="javascript:;"
                                            class="btn btn-icon btn-active-light btn-outline btn-outline-default btn-icon-gray-700 btn-active-icon-primary"
                                        >
                                            <i class="fas fa-bell"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content d-flex flex-column flex-column-fluid">
                    <div class="container-fluid">
                        <router-view v-slot="{ Component }">
                            <transition name="fade" mode="out-in">
                                <component :is="Component" />
                            </transition>
                        </router-view>
                    </div>
                </div>
                <div class="footer py-4 d-flex flex-lg-column">
                    <div
                        class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between"
                    >
                        <div class="text-dark order-2 order-md-1">
                            <span class="text-muted fw-bold me-1">{{ year }}©</span>
                            <a
                                href="https://www.eufabri.co"
                                target="_blank"
                                class="text-gray-800 text-hover-primary"
                                >eufabri.co</a
                            >
                        </div>
                        <ul class="menu menu-gray-600 menu-hover-primary fw-bolder order-1">
                            <li class="menu-item">
                                <a href="#" target="_blank" class="menu-link px-2">About</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Menu from '@layouts/components/Menu'
import Notifications from '@components/Notifications'
import Breadcrumb from '@layouts/components/Breadcrumb'
import UserModal from '@layouts/components/UserModal'
import { mapGetters, mapActions } from 'vuex'
import { FULL_LOGO, DEFAULT_AVATAR } from '@utils/constants'
export default {
    name: 'Internal',
    components: {
        Notifications,
        Breadcrumb,
        Menu,
        UserModal,
    },
    data() {
        return {
            logo: FULL_LOGO,
            defaultAvatar: DEFAULT_AVATAR,
            sidebar: false,
            showNotifications: false,
            navHover: null,
        }
    },
    computed: {
        ...mapGetters('auth', ['frame_type']),
        ...mapGetters('notification', ['notifications']),
        year() {
            const now = new Date()
            return now.getFullYear()
        },
    },
    methods: {
        ...mapActions('system', ['StartLoading', 'FinishLoading']),

        OpenNewTask(form_type) {
            this.OpenModal({
                task_id: null,
                mode: 'form',
                form_type: form_type,
                new_task: true,
            })
        },
        Caller() {
            this.StartLoading()

            Promise.all([
                    //
            ]).finally(() => {
                this.FinishLoading()
            })
        },
    },
    beforeMount() {
        this.Caller()
    },
}
</script>

<style lang="scss" scoped>
.btn-icon {
    i {
        font-size: 1.3rem;
    }
}
.logo-subtitle {
    position: absolute;
    bottom: 3px;
    right: 100px;
    font-family: 'Poppins';
    font-size: 210%;
    color: #ffffff;
    text-shadow: 0 0 5px black;
    font-weight: bolder;
}
.internal-container {
    @media (min-width: 992px) {
        .wrapper {
            .header {
                .header-brand {
                    display: flex;
                    justify-content: center;

                    img {
                        width: 110px;
                        height: auto;
                    }
                }
            }
        }
    }

    @media (max-width: 991.98px) {
        .wrapper {
            .header {
                .header-brand {
                    img {
                        width: 75px;
                        height: auto;
                    }
                }
            }
        }
    }
}
</style>
