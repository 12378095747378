<template>
	<div class="row">
		<div class="col-12">
			<MediaViewer :items='modelValue'/>
		</div>		
	</div>
</template>

<script>
	export default {

		name: 'History',
		props: {
			modelValue: {
				type: 		Array,
				default: 	() => []
			}
		},
		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>
</style>