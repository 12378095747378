<template>
  <div class="base-table-user">
    <span :class="`badge ps-2 bg-${color} d-flex flex-row align-items-center`">
      <div class="badge-avatar">
        <img
          :src="userAvatar"
          @error="
            (e) => {
              e.target.src = defaultAvatar
            }
          "
        />
      </div>
      <div class="d-flex flex-column align-items-start">
        <span class="badge-name">{{ userName }}</span>
        <small class="badge-description">{{ userDescription }}</small>
      </div>
    </span>
  </div>
</template>

<script>
import { DEFAULT_AVATAR } from '@utils/constants'
export default {
  name: 'BaseTableUser',
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {},
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    userAvatar() {
      return this.user && this.user.avatar_url
        ? this.user.avatar_url
        : this.defaultAvatar
    },
    userName() {
      return this.user && this.user.name ? this.user.name : ''
    },
    userDescription() {
      return this.user && this.user.description ? this.user.description : ''
    },
  },
  data() {
    return {
      defaultAvatar: DEFAULT_AVATAR,
    }
  },
}
</script>

<style lang="scss" scoped>
.base-table-user {
  width: fit-content;
  .badge {
    border-radius: 0.95rem;
    .badge-avatar {
      width: 42px;
      height: 42px;
      margin-right: 0.5rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.95rem;
      }
    }

    .badge-name {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }

    .badge-description {
      color: $gray-100;
    }
  }
}
</style>
