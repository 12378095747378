import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

export default async () => {
  /**
   *
   * TOOLTIPS
   *
   * */

  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  )

  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
}
