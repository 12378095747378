<template>
	<div class="form-row chat mt-0">
		<div class="col-12">
			<div class="conversation" ref="conversation">
				<template v-if="items.length > 0">
					<div
						v-for="item in items"
						:key="item.id"
					>
						<template v-if="item.divisor">
							<div class="row justify-content-center">
								<div class="col-auto">
									<div 
										class="alert alert-warning text-center my-4 clickable"
										@click="OpenTask(item.task_id)"
									>
										#{{ item.task_id }} - {{ item.task_name }}
									</div>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="row">
								<div 
									class="col-8"
									:class="{ 'offset-4': parseInt(user_id) === item.user_id }"
								>
									<div
										:class="{ 
											'me': parseInt(user_id) === item.user_id,
											'you': parseInt(user_id) !== item.user_id 
										}"
									>
										<template v-if="item.type === 1">
											<div class="message">
												<span 
													v-if="item.user && item.user.name"
													class="name"
												>
													{{ item.user.name }}
												</span>
												<div v-html="item.text"></div>
											</div>
										</template>
										<template v-else-if="item.type == 2">
											<div class="message">
												<span 
													v-if="item.user && item.user.name"
													class="name"
												>
													{{ item.user.name }}
												</span>
												<span class="font-italic" v-if="!item.status && item.deleted_at">
													ARQUIVO DELETADO
												</span>
												<div class="file-content" v-else>
													<template v-if="IsImage(item)">
														<div class="form-row align-items-center">
															<div class="col-12">
																<div class="img-content">
																	<img :src="GetImageLink(item)">
																</div>						
															</div>
															<div class="col-12 mt-3 text-right">
																<a 
																	href="javascript:;"
																	class="text-primary anchor-link" 
																	@click="DownloadFile(item)"
																>
																	Download
																</a>
															</div>
														</div>
													</template>
													<template v-else>
														<div class="form-row align-items-center">
															<div class="col-12">
																
																<div class="file text-center">
																	<i class="material-icons">insert_drive_file</i>
																	<p>{{ item.name }}</p>
																</div>					
															</div>
															<div class="col-12 mt-3 text-right">
																<a 
																	href="javascript:;"
																	class="text-primary anchor-link" 
																	@click="OpenFile(item)"
																>
																	Ver
																</a>
																|
																<a 
																	href="javascript:;"
																	class="text-primary anchor-link" 
																	@click="DownloadFile(item)"
																>
																	Download
																</a>
															</div>
														</div>
													</template>
												</div>
											</div>
										</template>
										<template v-else-if="item.type == 3">
											<div class="message">
												<span 
													v-if="item.user && item.user.name"
													class="name"
												>
													{{ item.user.name }}
												</span>
												<template v-if="!item.status && item.deleted_at">
													<span class="font-italic">
														AUDIO DELETADO
													</span>
												</template>
												<template v-else>
													<audio-player :src="item.file_url"/>
												</template>
											</div>
										</template>
										<small 
											class="time"
											:class="{ 
												'float-right': parseInt(user_id) === item.user_id
											}"
										>
											{{ ParseDate(item.created_at) }}
										</small>
									</div>
								</div>
							</div>
						</template>
					</div>
				</template>
				<template v-else>
					<div class="row align-items-center">
						<div class="col-12">
							<h5 class="no-data">Não há conteúdo para exibir.</h5>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'MediaViewer',
		props: {
			items: 	{
				type: 		Array,
				default: 	() => []
			}
		},
		data () {
			return {

			}
		},
		watch: {
			items: {
				handler(val)
				{
					this.ScrollToBottom()
				},
				deep: true,
				immediate: true
			}
		},
		computed: {
			...mapGetters('auth', [
				'user_id'
			]),
			...mapGetters('user', [
				'user'
			]),
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('task', [
				'OpenModal'
			]),
			OpenFile(file)
			{
				if(['png','jpg','jpeg','bmp','pdf'].indexOf(file.extension.toLowerCase()) >= 0)
				{
					const url 	= file.file_url

					let win 	= window.open(url,'_blank')
					win.focus()

				}else{

					this.DownloadFile(file)
				}
			},
			GetImageLink(file)
			{
				return file.file_url
			},
			IsImage(file)
			{
				return ['png','jpg','jpeg','bmp','webp'].indexOf(file.extension.toLowerCase()) >= 0
			},
			OpenTask(task_id)
			{
				this.OpenModal({
					mode: 			'options',
					form_type:  	'standard',
					new_task:  		false,
					task_id: 		task_id
				})
			},
			DownloadFile(file)
			{
				this.StartLoading()

				let mime 	= null

				switch(file.extension)
				{
					case 'ogg':
					case 'weba':
					case 'mp3':
						mime 		= `audio/${file.type}`
					break
					case 'wav':
						mime 		= `audio/x-wav`
					break
					case 'png':
					case 'jpeg':
					case 'jpg':
					case 'webp':
						mime 		= `image/${file.type}`
					break
					case 'zip':
					case 'pdf':
					case 'xml':
						mime 		= `application/${file.type}`
					break
					case '7z':
						mime 		= 'application/x-7z-compressed'
					break
					case 'rar':
						mime 		= 'application/x-rar-compressed'
					break
					case 'xlsx':
					case 'xls':
						mime 		= `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,`
					break
					case 'ppt':
						mime 		= 'application/vnd.ms-powerpoint'
					break
					case 'doc':
					case 'docx':
						mime 		= 'application/msword'
					break
					default:
						mime 		= 'application/octet-stream'
					break
				}

				const url 	= file.file_url

				window.axios({
				  	url: url,
				  	method: 'GET',
				  	responseType: 'blob',
				}).then((response) => {
				   	const url 		= window.URL.createObjectURL(new Blob([response.data], { type: mime }));
				   	const link 		= document.createElement('a');
				   	link.href 		= url;

				   	link.setAttribute('download', file.technical_name);
				   	document.body.appendChild(link);
				   	link.click();
				})
				.finally(() => {

					this.FinishLoading()

				})
			},
			StripHTML(string)
			{
				return string ? string.replace(/(<([^>]+)>)/gi, "") : ''
			},
			ScrollToBottom()
			{
				let scroll 			= this.$refs.conversation

				if(typeof scroll !== 'undefined')
				{
					scroll.scrollTop 	= scroll.scrollHeight;
				}
			},
			ParseDate(date)
			{
				return window.helpers.ParseDate(date)
			}
		}
	}
</script>

<style lang="css" scoped>
	.conversation .img-content
	{
		width: auto;
		height: auto;
	}

	.conversation .img-content img
	{
		width: 100%;
		height: auto;
		object-fit: cover;
		padding: .5px;
	}

	.conversation .message span.name
	{
	    font-weight: 700;
    	color: #003dc5;
	}
</style>