import Internal from '@layouts/Internal'

export default [
    {
        path: '/support',
        component: () => import('@layouts/components/EmptyComponent'),
        meta: {
            onMenu: true,
            onBreadcrumb: true,
            title: 'Suporte',
        },
        children: [
            {
                name: 'SupportTypeList',
                path: 'index',
                component: () => import('@modules/support/views/SupportTypeList'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Todos os tipos de suportes',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'SupportTypeCreate',
                path: 'create',
                component: () => import('@modules/support/views/SupportTypeForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Criar tipo de suporte',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'supportTypeDetails',
                path: ':supportId/details',
                props: true,
                component: () => import('@modules/support/views/SupportTypeForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Editar support',
                    layout: Internal,
                    alternativeMenu: 'SupportTypeList',
                },
            },
            {
                path: '',
                redirect: {
                    name: 'SupportTypeList',
                },
            },
        ],
    },
]
