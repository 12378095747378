import NProgress from 'nprogress'

NProgress.configure({
  showSpinner: false
})

export default (to) => {
  document.title = 'OLIE | ' + to.meta.title

  NProgress.done()
}
