<template>
	<div>
		<div 
			class="modal fade" 
			id="task-options-modal" 
			tabindex="-1" 
			aria-labelledby="task-options-modal" 
			aria-hidden="true"
			aria-modal="true"
		>
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<div class="form-row flex-nowrap align-items-center w-100">
							<div class="col">
								<div class="modal-title">
									Opções
								</div>
							</div>
							<div class="col-auto">
								<a 
						        	href="javascript:;"
						        	class="text-dark" 
						        	aria-label="Fechar"
						        	data-dismiss="modal" 
						        >
						          	<i class="material-icons">close</i>
						        </a>
							</div>
						</div>	
					</div>
					<div class="modal-body px-0">
						<div class="row" v-if="content">
							<div class="col-12">
								<p class="task-title mt-3">
									Atividade: #<strong>{{ content.task.id }}</strong> - {{ content.task.name }}
								</p>
							</div>
							<div class="col-12">
								<div class="options">
									<ul>
										<li @click="PreviewTask(content.task.id)">
											<a href="javascript:;">
												<p class="text-center text-uppercase m-0">
													Visualizar
												</p>
											</a>
										</li>
										<li v-if="diff_from_complete" @click="EditTask(content.task.id)">
											<a href="javascript:;">
												<p class="text-center text-uppercase m-0">
													Editar
												</p>
											</a>
										</li>										
										<li v-if="diff_from_complete" @click="ForwardTask(content.task.id)">
											<a href="javascript:;">
												<p class="text-center text-uppercase m-0">
													Encaminhar
												</p>
											</a>
										</li>
										<li v-if="content.task.status == 4" @click="SetTaskToDo(content.task.id)">
											<a href="javascript:;">
												<p class="text-center text-uppercase m-0">
													Retirar da pendência
												</p>
											</a>
										</li>
										<li v-if="enable_dot" @click="() => SetToDOTT(content.task.id, content.task.dickontable)">
											<a href="javascript:;">
												<p class="text-center text-uppercase m-0">
													{{!content.task.dickontable ? 'Colocar' : 'Retirar'}} p** na mesa
												</p>
											</a>
										</li>
										<li @click="CloneTask(content.task.id)">
											<a href="javascript:;">
												<p class="text-center text-uppercase m-0">
													Clonar
												</p>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="d-none modal-footer">
					</div>
				</div>
			</div>
		</div>
		<task-form
			ref="form"
			:content="content"
			@refresh='Refresh'
			@close="Close"
		/>
		<task-preview
			:content="content"
			@close="Close"
		/>
		<task-forward
			ref="forward"
			:content="content"
			@close="Close"
		/>
	</div>
</template>

<script>
	import Swal from 'sweetalert2/src/sweetalert2'
	import TaskForm from './Form'
	import TaskPreview from './Preview'
	import TaskForward from './Forward'
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 'Index',
		emits: {
			refresh: {
				type: Boolean
			}
		},
		components: {
			TaskForm,
			TaskPreview,
			TaskForward
		},
		data () {
			return {
				content: 	null
			}
		},
		computed: {
			...mapGetters('task', [
				'modal_open', 
				'modal_form_type', 
				'modal_mode', 
				'modal_task_id', 
				'modal_clone'
			]),
			diff_from_complete()
			{
				return this.content ? [1, 2, 3, 4, 6, 7].indexOf(this.content.task.status) > -1 : false
			},
			enable_dot()
			{
				return this.content ? [5, 8, 6].indexOf(this.content.task.status) === -1 : false
			}
		},
		watch: 	{
			modal_open(val)
			{
				this.ModalSelector(val)
			},
			modal_mode()
			{
				//this.ModalSelector(this.modal_open)
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('task', [
				'CloseModal', 'OpenModal'
			]),
			async ModalSelector(val)
			{
				if(val)
				{
					if(this.modal_task_id)
					{
						await this.GetTask()

					}else if(this.modal_clone)
					{
						this.content.task.id 			= 0
						this.content.task.designated 	= {}
						this.content.history 			= []
						this.content.task.users			= []

					}else{

						this.content 	= null
					}

					switch(this.modal_mode)
					{
						case 'form':
							window.jQuery('#task-options-modal').modal('hide')
							window.jQuery('#task-preview-modal').modal('hide')
							window.jQuery('#task-forward-model').modal('hide')
							window.jQuery('#task-form-modal').modal('show')
						break
						case 'preview':
							window.jQuery('#task-options-modal').modal('hide')
							window.jQuery('#task-form-modal').modal('hide')
							window.jQuery('#task-forward-model').modal('hide')
							window.jQuery('#task-preview-modal').modal('show')
						break
						case 'forward':
							window.jQuery('#task-options-modal').modal('hide')
							window.jQuery('#task-form-modal').modal('hide')
							window.jQuery('#task-preview-modal').modal('hide')
							window.jQuery('#task-forward-model').modal('show')
						break
						default:
							window.jQuery('#task-preview-modal').modal('hide')
							window.jQuery('#task-form-modal').modal('hide')
							window.jQuery('#task-forward-model').modal('hide')
							window.jQuery('#task-options-modal').modal('show')
						break
					}

				}else{

					window.jQuery('#task-options-modal').modal('hide')
					window.jQuery('#task-preview-modal').modal('hide')
					window.jQuery('#task-form-modal').modal('hide')
					window.jQuery('#task-forward-model').modal('hide')
				}
			},
			PreviewTask(task_id)
			{
				this.OpenModal({
					mode: 		'preview',
					form_type: 	'standard',
					task_id: 	task_id
				})
			},
			ForwardTask(task_id)
			{				
				this.OpenModal({
					mode: 		'forward',
					form_type: 	'standard',
					task_id: 	task_id
				})
			},
			EditTask(task_id)
			{
				this.OpenModal({
					mode: 		'form',
					form_type: 	'standard',
					task_id: 	task_id
				})
			},
			CloneTask(task_id)
			{
				this.OpenModal({
					mode: 		'form',
					form_type: 	'standard',
					task_id: 	null,
					clone: 		true
				})
			},
			Refresh()
			{
				this.$emit('refresh', true)
			},
			GetTask()
			{
				this.StartLoading()

				this.content = null

				return window.api.call('get',`/tasks/${this.modal_task_id}`)
					.then(({data}) => {
						this.content = { ...data.content }								
					})
					.catch(() => {
						Swal.fire({
							icon: 'error',
							title: 'Opss...',
							text: 'Houve um erro ao obter, contate o suporte.'
						})
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			SetTaskToDo(taskId)
			{
				this.StartLoading()

				return window.api.call('post', '/set-task-to-do',{
						task_id: 		taskId
					})
					.then(({data}) => {

						if(data.response)
						{
							Swal.fire({
								toast: 		true,
								position: 	'top',
								icon: 		'success',
								title: 		'Atividade enviada para execução !',
								timer: 		3500
							})

							this.Refresh()
								
						}else{

							Swal.fire({
			                    icon:       'error',
			                    title: 		'Opss...',
			                    text:       'Houve um erro ao concluir, contate o suporte.'
			                })
						}
					})
					.finally(async () => {

						await this.Close()

						this.FinishLoading()
					})
			},
			SetToDOTT(task_id, dickontable)
			{
				this.StartLoading()

				return window.api.call('put', `/tasks/${task_id}/dott`)
					.then(() => {
						Swal.fire({
							toast: true,
							position: 'top',
							timerProgressBar: true,
							icon: 'success',
							title: `P** na mesa ${dickontable ? 'des' : ''}ativado com sucesso.`,
							timer: 2000
						})

						this.Refresh()
					})
					.catch(() => {
						Swal.fire({
							icon: 		'error',
							title: 		'Opss...',
							text: 		'Houve um erro ao concluir, contate o suporte.'
						})
					})
					.finally(async () => {

						await this.Close()

						this.FinishLoading()
					})
				

			},
			async Close()
			{
				this.CloseModal()

				await this.$refs.form.ClearForm()
				await this.$refs.forward.ClearForm()
			}
		},
		mounted()
		{
			const vm = this

			window.jQuery('#task-options-modal, #task-preview-modal, #task-form-modal, #task-forward-model').on('hide.bs.modal', function (event) {
				if(vm.modal_open)
				{
					vm.Close()
				}
			})
		}
	}
</script>

<style lang="css" scoped>
	p.task-title
	{
		text-transform: uppercase;
		font-weight: 600;
	    font-size: .865rem;
	    padding: 0 1rem;
	    text-align: center;
	}

	.options ul
	{
		display: flex;
		flex-direction: column;
		padding: 0;
		list-style: none;
		margin-bottom: 0;
		max-height: calc(100vh - 110px);
	}

	.options li 
	{
		transition: all .2s ease-in-out;
		cursor: pointer;
	}

	.options li a
	{
		list-style: none;
		width: 100%;
		height: 100%;
		transition: all .2s ease-in-out;
		padding: 1rem;
		cursor: pointer;
	}

	.light-panel .options li a
	{
		color: #333;
	}

	.light-panel .options li:hover
	{
		background-color: #ccc;
		color: #000;
	}

	.light-panel .options li:hover a
	{
		color: #000;
	}

	.options li p
	{
		font-weight: 600;
		font-size: 1rem;
	}
</style>