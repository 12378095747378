const UPDATE_USER 			= (state, payload) => {
	state.user_id				= payload
}

const UPDATE_TOKEN 			= (state, payload) => {
	state.token 				= payload
}

const UPDATE_FRAME_TYPE 	= (state, payload) => {
	state.frame_type 			= payload
}

export {
	UPDATE_USER,
	UPDATE_TOKEN,
	UPDATE_FRAME_TYPE
}