<template>
  <div>
    <div class="row align-items-center">
      <div class="col-12">
        <div class="form-group">
          <label>Adicionar</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="checklist"
              ref="new"
              @keyup.enter="AddChecklist()"
            />
            <div class="input-group-append">
              <button
                class="btn btn-primary btn-icon"
                type="button"
                @click="AddChecklist()"
                :disabled="!checklist"
              >
                <i class="material-icons">check</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="text-uppercase font-weight-bold mt-2">
          Checklist
          <span v-if="checklists.length > 0"
            >-
            {{
              `${checklists.filter((x) => x.status).length} de ${
                checklists.length
              }`
            }}
          </span>
        </p>
      </div>
    </div>
    <transition name="fast-fade" mode="out-in">
      <div class="row checklists ml-1" v-if="checklists.length > 0" key="1">
        <div
          class="col-12 my-1 checklist"
          v-for="(cl, index) in checklists"
          :key="index"
        >
          <div class="row align-items-center">
            <div class="col-12">
              <div class="custom-control custom-checkbox mr-sm-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="`check_${index}`"
                  v-model="cl.status"
                />
                <label class="custom-control-label" :for="`check_${index}`">
                  {{ cl.text }}
                  <div
                    class="checklist-completed"
                    :class="{
                      selected: cl.status
                    }"
                  ></div>
                </label>
                <button
                  class="btn btn-danger btn-xs ml-2"
                  type="button"
                  @click="RemoveChecklist(cl)"
                >
                  remover
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else key="2">
        <div class="col-12">
          <div class="alert mb-0 alert-info">
            Não há checklists para exibir
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Checklist',
  emits: {
    'update:modelValue': {
      type: Array
    }
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      checklist: '',
      checklists: []
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.checklists = val
      }
    },
    checklists: {
      handler(val) {
        this.$emit('update:modelValue', val)
      },
      deep: true
    }
  },
  methods: {
    AddChecklist() {
      if (this.checklist) {
        this.checklists.push({
          text: this.checklist,
          status: false
        })

        this.checklist = ''

        this.$refs.new.focus()
      }
    },
    RemoveChecklist(checklist) {
      this.checklists = this.checklists.filter((item) => {
        return item.text != checklist.text
      })
    }
  }
}
</script>

<style lang="css" scoped></style>
