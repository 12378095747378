<template>
	<div class="modal-fullscreen modal-tabs preview-info">
		<div 
			class="modal fade" 
			id="task-preview-modal" 
			tabindex="-1" 
			data-backdrop="static" 
			data-keyboard="false" 
			aria-labelledby="task-preview-modal" 
			aria-hidden="true"
			aria-modal="true"
			v-if="content"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<div class="form-row flex-nowrap align-items-center w-100">
							<div class="col">
								<div class="modal-title">
									Visualizar Atividade
								</div>
							</div>
							<div class="col-auto">
							</div>
						</div>	
					</div>
					<div class="modal-throat">
						<div class="">
							<div class="row">
								<div class="col-12">
									<small class="text-muted text-uppercase"><b>Atividade</b></small>
									<h4 class="text-uppercase">
										<b>#{{content.task.id}} - {{ content.task.name }}</b>
									</h4>
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-250">
									<span>Duração: </span>
								</div>
								<div class="col-12 col-md">
									<b>{{ content.task.duration }} minuto(s)</b>
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-250">
									<span>Projeto: </span>
								</div>
								<div class="col-12 col-md">
									<b>{{ content.task.project.name }}</b>
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-250">
									<span>Urgência: </span>
								</div>
								<div class="col-12 col-md">
									<b>{{ GetLevel(content.task.urgency) }}</b>
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-250">
									<span>Impacto: </span>
								</div>
								<div class="col-12 col-md">
									<b>{{ GetLevel(content.task.impact) }}</b>
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-250">
									<span>Data/Hora inicial: </span>
								</div>
								<div class="col-12 col-md">
									<b>{{ ParseDate(content.task.start_datetime) }}</b>
								</div>
							</div>
							<div class="row">
								<div class="col-12 max-250">
									<span>Data/Hora final: </span>
								</div>
								<div class="col-12 col-md">
									<b>{{ ParseDate(content.task.end_datetime) }}</b>
								</div>
							</div>
						</div>
						<div class="row mt-4">
							<div class="col-12">
								<ul class="nav nav-pills">
									<li class="nav-item" role="presentation">
										<a 
											class="nav-link active"
											data-toggle="tab" 
											href="#media-preview-tab" 
											role="tab"
											aria-controls="media-preview-tab"
											aria-selected="true"
										>
											Conteudo
										</a>
									</li>
									
									<li class="nav-item" role="presentation">
										<a 
											class="nav-link"
											data-toggle="tab" 
											href="#file-preview-tab" 
											role="tab"
											aria-controls="file-preview-tab"
											aria-selected="false"
										>
											Arquivos
										</a>
									</li>
									<li class="nav-item" role="presentation">
										<a 
											class="nav-link"
											data-toggle="tab" 
											href="#checklist-preview-tab" 
											role="tab"
											aria-controls="checklist-preview-tab"
											aria-selected="false"
										>
											Checklist
										</a>
									</li>
									<li class="nav-item" role="presentation">
										<a 
											class="nav-link"
											data-toggle="tab" 
											href="#log-preview-tab" 
											role="tab"
											aria-controls="log-preview-tab"
											aria-selected="false"
										>
											Log
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-body">
						<div class="tab-content">
							<div 
								class="tab-pane fade show active"
								role="tabpanel" 
								id="media-preview-tab"
								aria-labelledby="media-preview-tab"
							>
								<MediaViewer :items="content.historic"/>
							</div>
							<div 
								class="tab-pane fade"
								role="tabpanel" 
								id="file-preview-tab"
								aria-labelledby="file-preview-tab"
							>
								<Attachments :items="only_files"/>
							</div>
							<div 
								class="tab-pane fade"
								role="tabpanel" 
								id="checklist-preview-tab"
								aria-labelledby="checklist-preview-tab"
							>
								<div 
									class="row checklists ml-1" 
									v-if="content.checklists.length > 0"
								>
									<div 
										class="col-12 my-1 checklist" 
										v-for="(checklist, index) in content.checklists" 
										:key="index"
									>
										<div class="row align-items-center">
											<div class="col-12">
												<div class="custom-control custom-checkbox mr-sm-2">
											        <input 
											        	type="checkbox" 
											        	class="custom-control-input"
											        	:id="`check_${index}`"
											        	:checked="checklist.status === 1"
											        >
											        <label class="custom-control-label" :for="`check_${index}`">
											        	{{ checklist.text }}
											        	<div 
											        		class="checklist-completed"
											        		:class="{
											        			'selected': checklist.status === 1
											        		}"
											        	></div>
											        </label>
										      	</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row" v-else>
									<div class="col-12">
										<div class="alert mb-0 alert-info">
											Não há checklists para exibir
										</div>
									</div>
								</div>
							</div>							
							<div 
								class="tab-pane fade"
								role="tabpanel" 
								id="log-preview-tab"
								aria-labelledby="log-preview-tab"
							>
								asd
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<div class="form-row">
							<div class="col text-right">
								<button 
									type="button" 
									class="btn"
									@click="CloseModal"
								>
									Fechar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	import Attachments from '../Attachments'
	export default {

		name: 'Preview',
		emits: {
			close: 	{
				type: Boolean
			}
		},
		props: 	{
			content: {
				type: 		Object,
				default: 	null
			}
		},
		components: {
			Attachments
		},
		data () {
			return {
				defaultAvatar: 	window.defaultAvatar
			}
		},
		computed: {
			...mapGetters('task', [
				'modal_open', 'modal_form_type', 'modal_mode', 'modal_task_id'
			]),
			only_files()
			{
				return this.content.media.filter( m => m.type === 2)
			}
		},
		methods: {
			...mapActions('task', [
				'CloseModal'
			]),
			GetAvatar(avatar_url)
			{
				let url 	= window.defaultAvatar

				if(avatar_url)
				{
					url 		= avatar_url
				}

				return url
			},
			GetLevel(level)
			{
				switch(level)
				{
					case 1:
						return 'Alto'
					case 2:
						return 'Médio'
					case 3:
						return 'Baixo'
				}
			},
			ParseDate(date)
			{
				return window.helpers.ParseDate(date)
			}
		}
	}
</script>

<style lang="css" scoped>
	.preview-info .modal-throat
	{
		padding: 0 1rem;
		font-size: .85em;
		line-height: 1.5;
	}

	.preview-info span
	{
		text-transform: uppercase;
	    color: #6c757d !important;
	    font-weight: 500;
	}
	.preview-info .custom-checkbox
	{
		position: relative;
	}

	.preview-info .custom-checkbox:before
	{
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

</style>