<template>
	<div class="row">
		<div class="col-12" v-if="activities.length > 0">
			<ul class="activities">
				<li
					v-for="activity in activities" 
					:key="activity.id"
				>
					<div class="row flex-nowrap align-items-center">
						<div class="col-auto">
							<div class="owner">
								<div class="user">
									<img 
										:src="GetAvatar(activity.user_id)"
										@error="(e) => { e.target.src = defaultAvatar }"
									>
								</div>
								<div 
									:class="`icon bg-${GetActivityMessage(activity).color}`"
								>
									<i class="material-icons">
										{{ GetActivityIcon(activity) }}
									</i>
								</div>
							</div>
						</div>
						<div class="col">
							<span class="status">{{ GetActivityMessage(activity).message }}</span>
							<br/> 
							<span class="description">{{ ParseDate(activity.datetime) }}</span>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="col-12" v-else>
			<div class="alert mb-0 alert-info">
				Não há logs para exibir
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {

		name: 'Log',
		props: 	{
			modalValue: 	{
				type: 		Array,
				default:  	() => []
			}
		},	
		data () {
			return {
				activities: 			[],
				defaultAvatar: 			window.defaultAvatar
			}
		},
		watch: {
			modalValue: {
				handler(val)
				{
					this.activities 		= val
				},
				deep: 	true,
			}
		},
		computed: {
			...mapGetters('user', [
				'user'
			])
		},
		methods: {
			GetAvatar(user_id)
			{	
				const user 		= this.user(user_id)

				let url 		= this.defaultAvatar

				if(user && user.avatar_url)
				{
					url 			= user.avatar_url
				}

				return url

			},
			GetActivityMessage(activity)
			{	
				let user 		= this.user(activity.user_id)
				user 			= user ? user : { name: 'LIGHTMIND' }

				switch(activity.type)
				{
					case 1:

						return {
							message: `${user.name} iniciou esta atividade`,
							color: 'info'
						}
					case 2:

						return {
							message: `${user.name} pausou esta atividade`,
							color: 'danger'
						}
					case 3:

						return {
							message: `${user.name} colocou esta atividade como pendente`,
							color: 'warning'
						}
					case 4:
					case 6:

						return {
							message: `${user.name} retornou esta atividade para fila de execução`,
							color: 'info'
						}
					case 5:

						return {
							message: `${user.name} enviou esta atividade para reavaliação`,
							color: 'dark'
						}
					case 7:

						return {
							message: `${user.name} solicitou a execução imediata desta atividade`,
							color: 'danger'
						}

					case 8:

						return {
							message: `${user.name} cancelou a atividade`,
							color: 'danger'
						}

					case 9:

						return {
							message: `${user.name} concluiu a atividade`,
							color: 'success'
						}
				}
			},
			GetActivityIcon(activity)
			{
				switch(activity.type)
				{
					case 1:
						return 'play_arrow'
					case 2:
						return 'pause'
					case 3:
						return 'eject'
					case 4:
					case 6:
						return 'playlist_add'
					case 5:
						return 'reply'
					case 7:
						return 'report'
					case 8:
						return 'close'
					case 9:
						return 'check'
				}
			},
			ParseDate(date)
			{
				return window.helpers.ParseDate(date)
			}
		}
	}
</script>

<style lang="css" scoped>
</style>