<template>
  <div class="page-title d-flex flex-column me-5">
    <h1 class="d-flex flex-column text-dark fw-bolder fs-2 mb-0">
      {{ title }}
    </h1>
    <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 pt-1">
      <li class="breadcrumb-item text-muted">
        <router-link class="text-muted text-hover-primary" :to="{ name: 'DashboardIndex' }">Dashboard</router-link>
        <span class="bullet bg-gray-300 ms-2 w-5px h-2px"></span>
      </li>

      <li
        v-for="(item, index) in items"
        :key="item.id"
        class="breadcrumb-item"
        :class="{
          'text-muted': items.length > index + 1,
          'text-dark': items.length === index + 1,
        }"
      >
        <template v-if="item.route && items.length > index + 1">
          <router-link
            :to="{ name: item.route }"
            class="text-muted text-hover-primary"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template v-else>
          {{ item.name }}
        </template>

        <template v-if="items.length > index + 1">
          <span class="bullet bg-gray-300 ms-2 w-5px h-2px"></span>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data() {
    return {}
  },
  computed: {
    title() {
      return this.items.length > 0 ? this.items[this.items.length - 1].name : ''
    },
    items() {
      const routes = this.$router.currentRoute.value.matched

      let items = []

      for (let [i, route] of Object.entries(routes)) {
        if ( ! route.meta.onBreadcrumb ) continue;

        let item = {
          id: `item-${i}`,
          name: '',
          route: null,
        }

        if (route.meta && route.meta.onBreadcrumb) {
          item.name = route.meta.title

          item.route = route.name || route.meta.referenceName
        }

        items.push(item)
      }

      return items
    },
  },
}
</script>

<style lang="scss" scoped></style>
