<template>
  <div
    v-if="localNav"
    :class="{
      'first-menu': index === 1,
      'menu-sub menu-sub-accordion menu-active-bg': index > 1,
    }"
  >
    <div
      class="menu-item"
      :class="{
        'menu-accordion': localNav.children,
        'hover show': localNav.active,
      }"
    >
      <template v-if="localNav.children">
        <span class="menu-link" @click="localNav.active = !localNav.active">
          <template v-if="index === 1">
            <span class="menu-icon">
              <span class="svg-icon svg-icon-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                    fill="black"
                  ></path>
                  <path
                    opacity="0.3"
                    d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
            </span>
          </template>
          <template v-else>
            <span class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
          </template>

          <span class="menu-title">{{ localNav.name }}</span>
          <span class="menu-arrow"></span>
        </span>
        <RecursiveMenu
          v-for="child in localNav.children"
          :key="child.id"
          :nav="child"
          :index="index + 1"
        />
      </template>
      <template v-else>
        <router-link
          class="menu-link"
          :class="{ active: localNav.active }"
          :to="{ name: localNav.route }"
        >
          <template v-if="index === 1">
            <span class="menu-icon">
              <span class="svg-icon svg-icon-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                    fill="black"
                  ></path>
                  <path
                    opacity="0.3"
                    d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
            </span>
          </template>
          <template v-else>
            <span class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
          </template>
          <span class="menu-title">{{ localNav.name }}</span>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecursiveMenu',
  props: {
    nav: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localNav: null,
    }
  },
  watch: {
    nav: {
      handler(nav) {
        this.localNav = nav
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style></style>
