const StartLoading = ({ commit, state }) => {
	commit('ADD')
}

const FinishLoading = ({ commit, state }) => {
	commit('REM')
}


export {
	StartLoading,
	FinishLoading
}