import { createStore } from 'vuex'

import user from '@store/user'
import auth from '@store/auth'
import system from '@store/system'

export default createStore({
  modules: {
    user,
    auth,
    system,
  }
})
