import External from '@layouts/External'

export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'AuthLogin',
    },
  },
  {
    path: '/auth',
    component: () => import('@layouts/components/EmptyComponent'),
    children: [
      {
        name: 'AuthLogin',
        path: 'login',
        component: () => import('@modules/auth/views/AuthLogin'),
        meta: {
          title: 'Login',
          layout: External,
        },
      },
      {
        name: 'AuthHub',
        path: 'hub/:token',
        props: true,
        component: () => import('@modules/auth/views/AuthHub'),
        meta: {
          title: 'Login',
          layout: External,
        },
      },
      {
        path: '',
        redirect: {
          name: 'AuthLogin',
        },
      },
    ],
  },
]
