<template>
	<div class="modal-fullscreen modal-tabs">
		<div 
			class="modal fade" 
			id="task-form-modal" 
			tabindex="-1" 
			data-backdrop="static" 
			data-keyboard="false" 
			aria-labelledby="task-form-modal" 
			aria-hidden="true"
			aria-modal="true"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<div class="form-row flex-nowrap align-items-center w-100">
							<div class="col">
								<div class="modal-title">
									<span>
										{{hasTaskId ? 'Editar' : 'Nova'}} 
										Atividade {{modal_form_type == 'scheduled' ? 'Agendada' : ''}}
									</span>
								</div>
							</div>
							<div class="col-auto">
							</div>
						</div>	
					</div>
					<div class="modal-throat">
						<div class="form-row">
							<div class="col-12">
								<ul class="nav nav-pills px-3" id="task-modal-tabs">
									<li class="nav-item" role="presentation">
										<a 
											class="nav-link active"
											data-toggle="tab" 
											href="#description-form-tab" 
											role="tab"
											aria-controls="description-form-tab"
											aria-selected="true"
										>
											Descrição
										</a>
									</li>
									<li class="nav-item" role="presentation">
										<a 
											class="nav-link"
											data-toggle="tab" 
											href="#media-form-tab" 
											role="tab"
											aria-controls="media-form-tab"
											aria-selected="false"
										>
											Conteudo
										</a>
									</li>
									<li class="nav-item" role="presentation">
										<a 
											class="nav-link"
											data-toggle="tab" 
											href="#checklist-form-tab" 
											role="tab"
											aria-controls="checklist-form-tab"
											aria-selected="false"
										>
											Checklist
										</a>
									</li>
									<li class="nav-item" v-if="hasTaskId" role="presentation">
										<a 
											class="nav-link"
											data-toggle="tab" 
											href="#historic-form-tab" 
											role="tab"
											aria-controls="historic-form-tab"
											aria-selected="false"
										>
											Histórico
										</a>
									</li>
									<li class="nav-item" v-if="hasTaskId" role="presentation">
										<a 
											class="nav-link"
											data-toggle="tab" 
											href="#log-form-tab" 
											role="tab"
											aria-controls="log-form-tab"
											aria-selected="false"
										>
											Log
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-body">
						<div class="tab-content" id="task-modal-tab-contents">
							<div 
								class="tab-pane fade show active"
								role="tabpanel" 
								id="description-form-tab"
								aria-labelledby="description-form-tab"
							>
								<description
									ref="form"
									:form-type="modal_form_type"
									:task-id="taskId"
									v-model="task"
								/>
							</div>
							<div 
								class="tab-pane fade"
								role="tabpanel" 
								id="media-form-tab"
								aria-labelledby="media-form-tab"
							>
								<media
									ref="media"
									:task-id="taskId"
									v-model="media"
								/>
							</div>
							<div 
							 	
								class="tab-pane fade"
								role="tabpanel" 
								id="checklist-form-tab"
								aria-labelledby="checklist-form-tab"
							>
								<checklist
									ref="checklist"
									:task-id="taskId"
									v-model="checklists"
								/>
							</div>
							<div 
								class="tab-pane fade"
								role="tabpanel" 
								id="historic-form-tab"
								aria-labelledby="historic-form-tab"
							>
								<Historic
									ref="historic"
									v-model="historic"
								/>
							</div>
							<div 
								class="tab-pane fade"
								role="tabpanel" 
								id="log-form-tab"
								aria-labelledby="log-form-tab"
							>
								<log
									ref="log"
									v-model="log"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<div class="row">
							<div class="col text-right">
								<button 
									type="button"
									class="btn"
									@click="CloseModal"
								>
									Fechar
								</button>
							</div>
							<div class="col-auto">
								<button 
									type="button" 
									class="btn btn-success"
									@click="Save"
								>
									Salvar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	import Swal from 'sweetalert2/src/sweetalert2'
	import Description from './components/Description'
	import Media from './components/Media'
	import Checklist from './components/Checklist'
	import Historic from './components/Historic'
	import Log from './components/Log'
	
	import { mapGetters, mapActions } from 'vuex'
	export default {

		name: 	'Form',
		emits: {
			refresh: {
				type: Boolean
			},
			close: 	{
				type: Boolean
			}
		},
		props: 	{
			content: 	{
				type: 		Object,
				default: 	null
			}
		},
		components: {
			Description,
			Media,
			Checklist,
			Historic,
			Log
		},
		data () {
			return {
				media: 			[],
				checklists:		[],
				historic: 		[],
				log: 			[],
				task: 			null
			}
		},
		computed: {
			...mapGetters('task', [
				'modal_open', 
				'modal_form_type', 
				'modal_mode', 
				'modal_task_id'
			]),
			hasTaskId()
			{
				return this.task && this.task.id
			},
			taskId()
			{
				return this.hasTaskId ? this.task.id : null
			}
		},
		watch: {
			content: {
				handler(val)
				{
					if(!window._.isEmpty(val))
					{
						if(val.media && val.media.length > 0)
						{
							this.media 		= val.media
						}

						if(val.checklists && val.checklists.length > 0)
						{
							this.checklists 		= val.checklists
						}

						if(val.historic && val.historic.length > 0)
						{
							this.historic 		= val.historic
						}

						if(val.log && val.log.length > 0)
						{
							this.log 		= val.log
						}

						if(val.task && !window._.isEmpty(val.task))
						{
							this.task 		= val.task
						}

					}else{

						this.ClearForm()

					}
				},
				deep: true
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('task', [
				'CloseModal'
			]),
			async Save()
			{
				let response = false
				let submit 	 = this.$refs.form.Submit()
				
				if (submit.response) {
					this.StartLoading()

					submit.form = this.task
					submit.form.source = this.modal_form_type
					submit.form.checklists = this.checklists
					submit.form.media = this.media
					submit.form.action = 'new'

					const callParams = {
						method: 'post',
						url: '/tasks'
					}

					if (this.hasTaskId) {
						submit.form.task_id	= this.task.id
						submit.form.action = 'update'
						callParams.method = 'put'
						callParams.url = `/tasks/${this.task_id}`	
					}
			
					await window.api.call(callParams.method, callParams.url, submit.form)
						.then(({data}) => {
							Swal.fire({
								toast: true,
								position: 'top',
								icon: 'success',
								title: 'Atividade salva com sucesso.',
								timer: 3000
							})

							this.ClearForm()
							this.$emit('refresh', true)
							this.CloseModal()
						})
						.catch(() => {
							Swal.fire({
								icon: 'error',
								title: 'Opss...',
								text: 'Houve um erro ao salvar, contate o suporte.'
							})
						})
						.finally(() => {
							response = true
							this.FinishLoading()
						})
				}

				return response
			},
			ClearForm()
			{
				this.media 		= []
				this.checklists = []
				this.log 		= []
				this.historic 	= []
				this.task 		= null

				this.$refs.form.Clear()
			},
			Close()
			{
				if(this.modal_form_type != 'interruption')
				{
					this.ClearForm()
				}

				this.CloseModal()
			}
		}
	}
</script>

<style lang="css" scoped>
</style>