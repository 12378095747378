import { createApp } from 'vue'

import FullCalendar from '@components/shared/FullCalendar'
import TaskModal from '@components/shared/task-modal/Index'
import AudioPlayer from '@components/shared/AudioPlayer'
import MediaViewer from '@components/shared/MediaViewer'
import CustomFields from '@components/shared/CustomFields'
import Money from 'v-money'
import VueTheMask from 'vue-the-mask'

window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/erp/`
const auth_token = localStorage.getItem('token')

if (auth_token) {
  window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth_token
}

import '@popperjs/core'
import 'bootstrap'
window.popper = require('@popperjs/core/dist/umd/popper.js')
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js')
window._ = require('lodash')
window.moment = require('moment')
window.momentTz = require('moment-timezone')
window.Pusher = require('pusher-js') 

import Echo from '@utils/echo'
window.Echo = new Echo()

import Helpers from '@utils/helpers'
window.helpers = new Helpers()

import router from '@router'
import store from '@store'

import App from './App'

import BaseComponents from '@components'

const app = createApp(App)

BaseComponents.register(app)

app
  .use(VueTheMask)
  .use(Money)
  .use(router)
  .use(store)
  .component('TaskModal', TaskModal)
  .component('AudioPlayer', AudioPlayer)
  .component('MediaViewer', MediaViewer)
  .component('CustomFields', CustomFields)
  .mount('#app')

