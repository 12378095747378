import Store from '@store'
import bootstrapActivator from '@utils/bootstrap-activator'

class Api {
  constructor() {}

  call(requestType, url, data = null, opts = {}) {
    return new Promise((resolve, reject) => {
      window.axios[requestType](url, data, opts)
        .then((response) => {
            resolve(response)
        })
        .catch(async (data) => {
            let response = data.response
            
            if (response.status && [400, 401, 418].indexOf(response.status) > -1) {
                await Store.dispatch('auth/Logout')
                reject(data)
                return
            }

            resolve(data)
        })
        .finally(() => {
          bootstrapActivator()
        })
    })
  }
}

export default new Api()
