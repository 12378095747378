<template>
	<div class="fullcalendar-container">
		<!--<full-calendar-core
			ref="fullCalendarCore"
			v-bind="configs"
			:events="events"
		/>-->
	</div>
</template>

<script>
	/*import FullCalendarCore from '@fullcalendar/vue'
	import dayGridPlugin from '@fullcalendar/daygrid'
	import timeGridPlugin from '@fullcalendar/timegrid'
	import listGridPlugin from '@fullcalendar/list'
	import brLocale from '@fullcalendar/core/locales/pt-br'
	import bootstrapPlugin from '@fullcalendar/bootstrap'*/
	export default {

		name: 'FullCalendar',
		props: {
			events: 	{
				type: Array,
				default: () => []
			}
		},
		components: 	{
			//FullCalendarCore
		},
		data () {
			return {
				/*configs: {
					locale: 			'pt-br',
					timeZone: 			'America/Sao_Paulo',
					defaultView: 		'dayGridMonth',
					//aspectRatio: 		2,
					header: 			{
						left: 				'title',
						center: 			'',
						right: 				'prev, next'
					},
					footer: 			{
						left: 				'listWeek, timeGridWeek, dayGridMonth',
						center: 			'',
						right: 				'today'
					},
					titleFormat: 		{
						year: 				'numeric',
						month: 				'2-digit'
					},
					locales: 			[
						brLocale
					],
					displayEventTime: 	false,
					slotDuration: 		'00:05',
					slotLabelInterval:  '00:05',
					slotLabelFormat: 	{
						hour: 				'2-digit',
						minute: 			'2-digit',
						omitZeroMinute: 	false
					},
					contentHeight: 		450,
					nowIndicator: 		true,
					allDaySlot: 		false,
					themeSystem: 		'bootstrap',
					plugins: 			[ 
						dayGridPlugin,
						timeGridPlugin,
						listGridPlugin,
						bootstrapPlugin
					]
				}*/
			}
		}
	}
</script>

<style lang="css" scoped>
</style>