import Internal from '@layouts/Internal'

export default [
    {
        path: '/changelog',
        component: () => import('@layouts/components/EmptyComponent'),
        meta: {
            onMenu: true,
            onBreadcrumb: true,
            title: 'Changelog',
        },
        children: [
            {
                name: 'ChangelogList',
                path: 'index',
                component: () => import('@modules/changelog/views/ChangelogList'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Todos os changelogs',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'ChangelogCreate',
                path: 'create',
                component: () => import('@modules/changelog/views/ChangelogForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Criar changelog',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'ChangelogDetails',
                path: ':changelogId/details',
                props: true,
                component: () => import('@modules/changelog/views/ChangelogForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Editar changelog',
                    layout: Internal,
                    alternativeMenu: 'ChangelogList',
                },
            },
            {
                path: '',
                redirect: {
                    name: 'ChangelogList',
                },
            },
        ],
    },
]
