import Internal from '@layouts/Internal'

export default [
  {
    path: '/admins',
    component: () => import('@layouts/components/EmptyComponent'),
    meta: {
      onMenu: true,
      onBreadcrumb: true,
      title: 'Admins',
    },
    children: [
      {
        name: 'AdminList',
        path: 'all-admins',
        component: () => import('@modules/admin/views/AdminList'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          title: 'All Admins',
          onMenu: true,
          layout: Internal,
        },
      },
      {
        name: 'AdminCreate',
        path: 'create',
        component: () => import('@modules/admin/views/AdminCreate'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          title: 'Admin Create',
          onMenu: true,
          layout: Internal,
        },
      },
      {
        name: 'AdminDetails',
        path: ':adminId/details',
        props: true,
        component: () => import('@modules/admin/views/AdminDetails'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          title: 'Admin Details',
          layout: Internal,
          alternativeMenu: 'AdminList',
        },
      },
      {
        path: '',
        redirect: {
          name: 'AdminList',
        },
      },
    ],
  },
]
