<template>
  <form>
    <template v-if="formType == 'scheduled'">
      <div class="form-row">
        <div class="col-12">
          <div class="form-group">
            <label>Atividade</label>
            <input
              type="text"
              :class="{ 'is-invalid': v$.form.name.$error }"
              v-model="v$.form.name.$model"
              class="form-control"
              maxlength="160"
            />
            <div class="invalid-feedback">
              Obrigatório
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <autocomplete
            label="Cliente"
            :items="customers"
            item-text="name"
            item-value="id"
            placeholder="Informe o cliente"
            v-model="form.customer"
          >
          </autocomplete>
        </div>
        <div class="col-12 col-md-6">
          <autocomplete
            label="Projeto"
            :items="filtered_customer_projects"
            item-text="name"
            item-value="id"
            :validate="{
              bool: v$.form.project.$error,
              text: 'Obrigatório'
            }"
            placeholder="Informe algum projeto"
            v-model="v$.form.project.$model"
          >
          </autocomplete>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <label>Data de inicio</label>
            <input
              type="date"
              class="form-control"
              v-model="v$.form.start_date.$model"
            />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <label>Horário de inicio</label>
            <input
              type="tel"
              v-mask="['##:##']"
              class="form-control"
              :class="{ 'is-invalid': v$.form.start_time.$error }"
              v-model="v$.form.start_time.$model"
            />
            <div
              class="invalid-feedback"
              v-if="!v$.form.start_time.ValidateTime"
            >
              Horário Invalido
            </div>
            <div class="invalid-feedback" v-else>
              Obrigatório
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <label>Duração</label>
            <input
              type="text"
              placeholder="Em minutos"
              v-model="v$.form.duration.$model"
              class="form-control"
              :class="{ 'is-invalid': v$.form.duration.$error }"
              v-mask="['####']"
            />
            <div class="invalid-feedback">
              Obrigatório
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label>Enviar convite (.ics)</label>
            <input-tag
              v-model="form.external_guests"
              validate="email"
              placeholder="Informe um email"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="formType == 'interruption'">
      <div class="form-row">
        <div class="col-12">
          <div class="form-group">
            <label>Atividade</label>
            <input
              type="text"
              :class="{ 'is-invalid': v$.form.name.$error }"
              v-model="v$.form.name.$model"
              class="form-control"
              maxlength="160"
            />
            <div class="invalid-feedback">
              Obrigatório
            </div>
          </div>
        </div>
        <div class="col-12">
          <autocomplete
            label="Projeto"
            :items="projects"
            :allow-create="true"
            @create-command="QuickProjectCreation"
            item-text="name"
            item-value="id"
            :validate="{
              bool: v$.form.project.$error,
              text: 'Obrigatório'
            }"
            placeholder="Informe algum projeto"
            v-model="v$.form.project.$model"
          >
          </autocomplete>
        </div>
      </div>
    </template>
    <template v-else-if="formType == 'standard'">
      <div class="form-row">
        <div class="col-12">
          <div class="form-group">
            <label>Atividade</label>
            <input
              type="text"
              :class="{ 'is-invalid': v$.form.name.$error }"
              v-model="v$.form.name.$model"
              class="form-control"
              maxlength="160"
            />
            <div class="invalid-feedback">
              Obrigatório
            </div>
          </div>
        </div>
        <div class="col-12 col-md">
          <autocomplete
            label="Cliente"
            :items="customers"
            item-text="name"
            item-value="id"
            placeholder="Informe o cliente para filtrar o projeto"
            v-model="form.customer"
          >
            <template #item="{ item }">
              <div class="customer-chip">
                <small>{{
                  item.customer_type === 2 ? 'Filial' : 'Matriz'
                }}</small>
                <p class="m-0">
                  <b>{{ item.name }}</b>
                </p>
              </div>
            </template>
          </autocomplete>
        </div>
        <div class="col col-md-2">
          <div class="form-group">
            <label>Duração</label>
            <input
              type="text"
              placeholder="Em minutos"
              v-model="v$.form.duration.$model"
              class="form-control"
              :class="{ 'is-invalid': v$.form.duration.$error }"
              v-mask="['####']"
            />
            <div class="invalid-feedback">
              Obrigatório
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 col-md-4" v-if="taskId ? form.status === 1 : false">
          <autocomplete
            label="Designado"
            :items="users"
            item-text="name"
            item-value="id"
            :validate="{
              bool: v$.form.designated.$error,
              text: 'Obrigatório'
            }"
            :custom-filter="DesignatedFilter"
            placeholder="Informe um nome de usuário"
            v-model="v$.form.designated.$model"
          >
            <template #item="{ item }">
              <div class="chip">
                <div class="avatar">
                  <img
                    :src="GetAvatar(item.avatar_url)"
                    @error="
                      (e) => {
                        e.target.src = defaultAvatar
                      }
                    "
                  />
                </div>
                <div class="name">
                  {{ item.name }}
                </div>
              </div>
            </template>
          </autocomplete>
        </div>
        <div class="col-12 col-md">
          <autocomplete
            label="Projeto"
            :items="filtered_customer_projects"
            :allow-create="true"
            @create-command="QuickProjectCreation"
            item-text="name"
            item-value="id"
            :validate="{
              bool: v$.form.project.$error,
              text: 'Obrigatório'
            }"
            placeholder="Informe algum projeto"
            v-model="v$.form.project.$model"
          >
          </autocomplete>
        </div>
        <div class="col-6 col-md-2">
          <div class="form-group">
            <label>Urgência</label>
            <select
              class="form-control"
              v-model="v$.form.urgency.$model"
              :class="{ 'is-invalid': v$.form.urgency.$error }"
            >
              <option value="1">Alta</option>
              <option value="2">Média</option>
              <option value="3">Baixa</option>
            </select>
            <div class="invalid-feedback">
              Obrigatório
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="form-group">
            <label>Impacto</label>
            <select
              class="form-control"
              v-model="v$.form.impact.$model"
              :class="{ 'is-invalid': v$.form.impact.$error }"
            >
              <option value="1">Alto</option>
              <option value="2">Médio</option>
              <option value="3">Baixo</option>
            </select>
            <div class="invalid-feedback">
              Obrigatório
            </div>
          </div>
        </div>
      </div>
      <transition name="fast-fade" mode="out-in">
        <div class="form-row" v-show="show_advanced">
          <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
              <label>Disponivel: DATA</label>
              <input
                type="date"
                class="form-control"
                v-model="v$.form.start_date.$model"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
              <label>Disponivel: HORA</label>
              <input
                type="tel"
                v-mask="['##:##']"
                class="form-control"
                :class="{ 'is-invalid': v$.form.start_time.$error }"
                v-model="v$.form.start_time.$model"
              />
              <div
                class="invalid-feedback"
                v-if="!v$.form.start_time.ValidateTime"
              >
                Horário Invalido
              </div>
              <div class="invalid-feedback" v-else>
                Obrigatório
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6  col-md-3">
            <div class="form-group">
              <label>Vencimento: DATA</label>
              <input
                type="date"
                class="form-control"
                v-model="v$.form.end_date.$model"
                :min="v$.form.start_date.$model"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="form-group">
              <label>Vencimento: HORA</label>
              <input
                type="tel"
                v-mask="['##:##']"
                class="form-control"
                :class="{ 'is-invalid': v$.form.end_time.$error }"
                v-model="v$.form.end_time.$model"
              />
              <div
                class="invalid-feedback"
                v-if="!v$.form.end_time.ValidateTime"
              >
                Horário Invalido
              </div>
              <div class="invalid-feedback" v-else>
                Obrigatório
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div class="form-row justify-content-end">
        <div class="col-auto">
          <a
            href="javascript:;"
            class="anchor-link text-primary"
            @click="show_advanced = !show_advanced"
          >
            {{ !show_advanced ? 'exibir' : 'esconder' }} config. data
          </a>
        </div>
      </div>
    </template>
    <template v-if="!taskId && formType != 'interruption'">
      <div class="form-row">
        <div class="col-12">
          <autocomplete
            label="Adicione usuários"
            :items="filteredUsers"
            item-text="name"
            item-value="id"
            :custom-filter="UsersFilter"
            placeholder="Informe um usuário"
            :validate="{
              bool: v$.form.users.$error,
              text: 'Obrigatório'
            }"
            v-model="selectedUser"
          >
            <template #item="{ item }">
              <div class="chip">
                <div class="avatar">
                  <img
                    :src="GetAvatar(item.avatar_url)"
                    @error="
                      (e) => {
                        e.target.src = defaultAvatar
                      }
                    "
                  />
                </div>
                <div class="name">
                  {{ item.name }}
                </div>
              </div>
            </template>
          </autocomplete>
        </div>
        <div class="col-12">
          <div class="mb-3">
            <div class="row">
              <div class="col-12">
                <transition name="fade" mode="out-in">
                  <div class="row" v-if="form && form.users.length > 0" key="1">
                    <div class="col-12">
                      <label>Selecionados</label>
                    </div>
                    <div class="col-12">
                      <transition-group
                        tag="div"
                        class="form-row"
                        name="fade"
                        mode="out-in"
                      >
                        <div
                          class="col-auto"
                          v-for="user in form.users"
                          :key="user.id"
                        >
                          <div class="overlay-function mb-2">
                            <div class="card bg-secondary">
                              <div class="px-2 py-1">
                                <div
                                  class="form-row flex-nowrap align-items-center"
                                >
                                  <div class="col-auto">
                                    <img
                                      class="img-avatar"
                                      :src="GetAvatar(user.avatar_url)"
                                      @error="
                                        (e) => {
                                          e.target.src = defaultAvatar
                                        }
                                      "
                                    />
                                  </div>
                                  <div class="col">
                                    <p class="m-0">
                                      {{ user.name }}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="overlay-action"
                                  @click="RemoveSelectedUser(user)"
                                >
                                  Remover
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition-group>
                    </div>
                  </div>
                  <div class="row" v-else key="2">
                    <div class="col-12">
                      <div class="alert mb-0 alert-info">
                        Não há usuários selecionados
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </form>
</template>

<script>
import Swal from 'sweetalert2/src/sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

const ValidateTime = (value) => {
  if (typeof value !== 'undefined' && value.length >= 5) {
    const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g

    let result = value.match(pattern)

    return result ? true : false
  }

  return true
}

export default {
  name: 'Description',
  emits: {
    'set-to-dot': {
      type: Boolean
    },
    'update:modelValue': {
      type: Object
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    taskId: {
      type: Number,
      required: false,
      default: null
    },
    formType: {
      type: String,
      default: 'standard' //scheduled / interruption
    }
  },
  data() {
    return {
      defaultAvatar: window.defaultAvatar,
      selectedUser: {},
      show_advanced: false,
      form: {
        name: '',
        duration: '',
        status: 1,
        designated: {},
        customer: {},
        users: [],
        project: {},
        urgency: 3,
        impact: 3,
        start_date: window.moment().format('YYYY-MM-DD'),
        start_time: '00:00',
        end_date: window
          .moment()
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        end_time: '00:00',
        external_guests: []
      }
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        if (val && !window._.isEmpty(val)) {
          this.form = {
            ...val,
            customer: val.project.customer ?? {}
          }
        }
      },
      deep: true
    },
    selectedUser(val) {
      if (!window._.isEmpty(val)) {
        this.form.users.push(val)

        this.selectedUser = {}
      }
    },
    start_date(val) {
      if (val > this.form.end_date) {
        this.form.end_date = val
      }
    },
    'form.customer': {
      handler(val) {
        this.form.project = {}
      },
      deep: true
    },
    'form.project': {
      handler(val) {
        if (window._.isEmpty(val) && this.form.project_id) {
          this.form.project = { id: this.form.project_id }
        }
      }
    }
  },
  computed: {
    ...mapGetters('user', ['users']),
    ...mapGetters('project', ['running_projects']),
    ...mapGetters('customer', ['customers']),
    filteredUsers() {
      const users = this.users.filter((user) => {
        return this.form && this.form.users
          ? !this.form.users.find(
              (user_scheduled) => user_scheduled.id === user.id
            )
          : true
      })

      return users
    },
    filtered_customer_projects() {
      let projects = this.running_projects

      if (!window._.isEmpty(this.form.customer)) {
        projects = projects.filter((project) => {
          return project.customer_id === this.form.customer.id
        })
      }

      return projects
    },
    start_date() {
      return this.form && this.form.start_date ? this.form.start_date : null
    }
  },
  validations() {
    let obj = {
      form: {
        name: {
          required
        },
        project: {
          required
        }
      }
    }

    if (this.formType == 'standard') {
      obj.form = {
        ...obj.form,
        urgency: {
          required
        },
        impact: {
          required
        },
        end_date: {
          required
        },
        end_time: {
          required,
          ValidateTime
        }
      }
    }

    if (this.formType != 'interruption') {
      obj.form = {
        ...obj.form,
        duration: {
          required
        },
        start_date: {
          required
        },
        start_time: {
          required,
          ValidateTime
        }
      }

      if (this.taskId) {
        obj.form = {
          ...obj.form,
          designated: {
            required
          }
        }
      } else {
        obj.form = {
          ...obj.form,
          users: {
            required
          }
        }
      }
    }
    return obj
  },
  methods: {
    ...mapActions('project', ['GetProjects']),
    ...mapActions('system', ['StartLoading', 'FinishLoading']),
    GetAvatar(avatar_url) {
      let url = window.defaultAvatar

      if (avatar_url) {
        url = avatar_url
      }

      return url
    },
    QuickProjectCreation(project_name) {
      this.StartLoading()

      let obj = {
        name: project_name
      }

      if (!window._.isEmpty(this.form.customer)) {
        obj.customer_id = this.form.customer.id
      }

      return window.api
        .call('post', '/quick-project-creation', obj)
        .then(async ({ data }) => {
          if (data.response) {
            Swal.fire({
              icon: 'success',
              text: 'Projeto criado com sucesso.',
              toast: true,
              position: 'top',
              timer: 3000
            })

            await this.GetProjects()

            this.form.project = data.project
          } else {
            if (data.exists) {
              Swal.fire({
                icon: 'error',
                title: 'Opss...',
                text: 'Já existe um projeto com este nome.'
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Opss...',
                text: 'Houve um erro ao concluir, contate o suporte.'
              })
            }
          }
        })
        .finally(() => {
          this.FinishLoading()
        })
    },
    UsersFilter(item, queryText) {
      const searchText = queryText.toLowerCase()

      let arrSearch = ['name', 'email', 'description']

      let resultSearch = arrSearch
        .map((param) =>
          item[param]
            ? window.helpers
                .RemoveAcento(item[param].toString().toLowerCase())
                .indexOf(searchText) > -1
            : false
        )
        .filter((param) => param)

      return resultSearch.length > 0
    },
    RemoveSelectedUser(user) {
      this.form.users = this.form.users.filter((item) => item.id != user.id)
    },
    Clear() {
      this.form = {
        name: '',
        duration: '',
        status: 1,
        designated: {},
        customer: {},
        users: [],
        project: {},
        urgency: 3,
        impact: 3,
        start_date: window.moment().format('YYYY-MM-DD'),
        start_time: '00:00',
        end_date: window
          .moment()
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        end_time: '00:00'
      }

      this.v$.$reset()
    },
    SetToDOT() {
      this.$emit('set-to-dot', true)
    },
    Submit() {
      let valid = false

      this.v$.form.$touch()

      if (!this.v$.form.$invalid) {
        valid = true

        this.$emit('update:modelValue', this.form)
      }

      return {
        response: valid
      }
    },
    DesignatedFilter(item, queryText) {
      const searchText = queryText.toLowerCase()

      let arrSearch = ['name', 'email', 'description']

      let resultSearch = arrSearch
        .map((param) =>
          item[param]
            ? window.helpers
                .RemoveAcento(item[param].toString().toLowerCase())
                .indexOf(searchText) > -1
            : false
        )
        .filter((param) => param)

      return resultSearch.length > 0
    }
  }
}
</script>

<style lang="css" scoped>
.customer-chip {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  text-transform: uppercase;
}
</style>
