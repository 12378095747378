<template>
  <div>
    <transition name="fade" mode="out-in">
      <Component :is="layout">
        <router-view />
      </Component>
    </transition>
    <BaseLoader />
  </div>
</template>

<script>
import BaseLoader from '@components/BaseLoader'
import External from '@layouts/External'
export default {
  name: 'Index',
  components: {
    BaseLoader,
  },
  data() {
    return {}
  },
  computed: {
    layout() {
      return this.$route.meta.layout ?? External
    },
  },
}
</script>

<style lang="scss">
//@import './assets/sass/_shared.scss'
</style>
