const current_user		= (state) => {
	return state.admin
}

const admin 				= (state) => (id) => {
	return state.admin
}

export {
	current_user,
	admin
}