import router from '@router'
import api from '@utils/api'
import Swal from 'sweetalert2/src/sweetalert2'

const GetAuthUser = ({ commit, state, dispatch }, payload) => {
  if (state.token) {
    dispatch('system/StartLoading', null, {
      root: true,
    })

    return api
      .call('get', '/get-auth-admin')
      .then(({ data }) => {
        if (data.response) {
          commit('UPDATE_USER', data.user_id)

          localStorage.setItem('user_id', data.user_id)
        }
      })
      .finally(() => {
        dispatch('system/FinishLoading', null, {
          root: true,
        })
      })
  }
}

const LogoutCore = (commit) => {
  commit('UPDATE_USER', null)
  commit('UPDATE_TOKEN', null)
  commit('UPDATE_FRAME_TYPE', null)

  localStorage.removeItem('token')
  localStorage.removeItem('user_id')
  localStorage.removeItem('frame_type')

  window.location.reload()
}

const Logout = ({ commit, state, dispatch }) => {
  dispatch('system/StartLoading', null, {
    root: true,
  })

  return window.axios
    .post('/logout', {
      token: state.token,
    })
    .finally(async () => {
      await LogoutCore(commit)

      dispatch('system/FinishLoading', null, {
        root: true,
      })
    })
}

const LoginCore = (data, commit) => {
  commit('UPDATE_USER', data.user_id)
  commit('UPDATE_TOKEN', data.token)

  localStorage.setItem('token', data.token)
  localStorage.setItem('user_id', data.user_id)

  window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
}

const Login = ({ commit, dispatch }, payload) => {
  dispatch('system/StartLoading', null, {
    root: true,
  })

  return new Promise((resolve, reject) => {
    window.axios
      .post('/login', {
        username: payload.username,
        password: payload.password,
        token: payload.token
      })
      .then(async ({ data }) => {
        if (data.response) {
          await LoginCore(data, commit)

          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(({ response }) => {
        reject(response)
      })
      .finally(() => {
        dispatch('system/FinishLoading', null, {
          root: true,
        })
      })
  })
}

const HubLogin = ({ commit, dispatch }, payload) => {
  dispatch('system/StartLoading', null, {
    root: true,
  })

  return new Promise((resolve, reject) => {
    window.axios
      .post('/hub-redirect', {
        token: payload.token,
      })
      .then(async ({ data }) => {
        if (data.response) {
          await this.LoginCore(data)

          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(({ resp }) => {
        reject(resp)
      })
      .finally(() => {
        dispatch('system/FinishLoading', null, {
          root: true,
        })
      })
  })
}

const RenewAuth = ({ commit }, payload) => {
  commit('UPDATE_USER', payload.user_id)
  commit('UPDATE_TOKEN', payload.token)

  localStorage.setItem('token', payload.token)
  localStorage.setItem('user_id', payload.user_id)

  window.axios.defaults.headers.common['Authorization'] =
    'Bearer ' + payload.token

  //window.Echo.renew()
}

export { GetAuthUser, Logout, Login, HubLogin, RenewAuth }
