<template>
  <div class="base-table">
    <div class="row align-items-center">
      <div class="col-12">
        <div
          class="table-responsive"
          v-if="headers.length > 0 && modelValue.length > 0"
        >
          <table
            class="table table-rounded table-hover table-row-dashed align-middle fs-6 my-0 pb-3 dataTable no-footer"
            :class="table_class"
          >
            <thead>
              <tr class="fw-bolder fs-6 text-gray-800">
                <th v-if="checkbox">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      id="checkbox-all"
                      v-model="check_all"
                    />
                    <label for="checkbox-all"></label>
                  </div>
                </th>
                <th
                  v-for="(header, index) in headers"
                  :key="index"
                  class=""
                  :class="{
                    'text-center': header.align == 'center',
                    'text-left': header.align == 'left',
                    'text-right': header.align == 'right',
                    sortable: header.value && header.sortable,
                    active: sortedBy.column == header.value,
                  }"
                  @click="SortData(header)"
                >
                  <div class="th-content">
                    {{ header.text }}
                    <div class="sort-icon">
                      <i
                        :class="`bi bi-${sortedBy.icon}`"
                        v-if="sortedBy.column == header.value"
                      ></i>
                    </div>
                    <template v-if="excel_filter && header.sortable">
                      <div
                        class="filter"
                        :class="{
                          active:
                            excel_filtered_items[`filter-${index}`] &&
                            excel_filtered_items[`filter-${index}`].length > 0,
                        }"
                        @click.stop
                      >
                        <a
                          href="javascript:;"
                          class="btn btn-light-danger btn-icon px-2 py-1 me-2"
                          :ref="`filter-${index}`"
                          @click="() => OpenExcelFilter(index)"
                        >
                          <i class="bi bi-filter font-18"></i>
                          <template
                            v-if="
                              excel_filtered_items[`filter-${index}`] &&
                              excel_filtered_items[`filter-${index}`].length > 0
                            "
                          >
                            <div class="indicator">
                              {{
                                excel_filtered_items[`filter-${index}`].length
                              }}
                            </div>
                          </template>
                        </a>
                      </div>
                    </template>
                  </div>
                </th>
              </tr>
            </thead>
            <transition name="fast-fade" mode="out-in">
              <tbody v-if="filtered_items.length > 0">
                <tr
                  v-for="(item, index) in filtered_items"
                  :class="`${tr_button ? 'tr-btn' : ''} ${
                    row_color
                      ? item.row_status_color
                        ? item.row_status_color
                        : item.row_color
                      : ''
                  }`"
                  :key="index * Math.random()"
                  @click="ByClickOnTR(item)"
                >
                  <td v-if="checkbox" scope="col">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        :id="`checkbox-${index}`"
                        :value="item[checkbox_value]"
                        v-model="selected_checkboxes"
                        @change="EmitCheckboxes"
                      />
                      <label :for="`checkbox-${index}`"></label>
                    </div>
                  </td>
                  <template v-if="disable_body_slot">
                    <td v-for="(header, i) in headers" :key="i">
                      {{ GetTDContent(item, header) }}
                    </td>
                  </template>
                  <template v-else>
                    <slot name="body" :item="item" :index="index"></slot>
                  </template>
                </tr>
              </tbody>
            </transition>
            <tfoot v-if="items.length > 0">
              <tr>
                <slot name="footer" :items="items"></slot>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <template v-if="items.length > 0 && filtered_items.length > 0">
      <div class="row align-items-center">
        <div class="col-auto pe-1">
          <select
            class="form-select form-select-sm form-select-solid"
            v-model="itemsPerPage"
          >
            <option :value="10">10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
            <option :value="100">100</option>
            <option :value="250">250</option>
          </select>
        </div>
        <div class="col text-center text-md-start align-self-center">
          <span class="counter text-muted my-2 fw-bolder">
            {{
              paginate
                ? `Showing ${paginateSetup.initItem} to ${paginateSetup.endItem} of ${items.length} items`
                : `Total: ${items.length} items`
            }}
          </span>
        </div>
        <div class="col-12 col-md-auto mt-2 text-center" v-if="paginate">
          <ul class="pagination">
            <li class="page-item previous">
              <a
                href="javascript:;"
                class="page-link"
                :disabled="paginateSetup.page === 1"
                @click="WalkPaginate('prev')"
              >
                <i class="fas fa-angle-left"></i>
              </a>
            </li>
            <li
              v-for="page in pagesIndex"
              :key="page"
              class="page-item"
              :class="{ active: page === paginateSetup.page }"
            >
              <template v-if="page">
                <a
                  href="javascript:;"
                  class="page-link"
                  @click="WalkPaginate(page)"
                >
                  <b>{{ page }}</b>
                </a>
              </template>
              <template v-else> <span class="valign-sub">...</span></template>
            </li>
            <li class="page-item next">
              <a
                href="javascript:;"
                class="page-link"
                :disabled="
                  paginateSetup.page === paginateSetup.totalPages ||
                  filtered_items.length < 1
                "
                @click="WalkPaginate('next')"
              >
                <i class="fas fa-angle-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </template>

    <div
      class="modal fade"
      id="excel-filter"
      tabindex="-1"
      aria-labelledby="excel-filter"
      aria-hidden="true"
      aria-modal="true"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <div class="form-row flex-nowrap align-items-center w-100">
              <div class="col">
                <div class="modal-title">
                  Filtrar por: {{ excel_filter_column_object.text ?? '' }}
                </div>
              </div>
              <div class="col-auto">
                <a
                  href="javascript:;"
                  class="text-dark"
                  aria-label="Fechar"
                  @click="() => { excel_filter_column = null }"
                >
                  <i class="material-icons">close</i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="row align-items-center">
              <div class="col-12 mt-2">
                <div class="form-group">
                  <input
                    type="search"
                    v-model="excel_filter_search"
                    class="form-control"
                    placeholder="Buscar"
                  />
                </div>
              </div>
              <template v-if="excelFilterItems.length > 0">
                <div
                  class="col-12"
                  v-for="(item, i) in excelFilterItems"
                  :key="i"
                >
                  <div class="form-check">
                    <input
                      type="checkbox"
                      :id="`filter-checkbox-${i}`"
                      v-model="excel_filtered_items[excel_filter_column]"
                      :value="item.value"
                    />
                    <label :for="`filter-checkbox-${i}`">
                      {{ item.label ?? '( vazio )' }}
                    </label>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="col-12">
                  <p class="m-0">Não há dados nesta coluna</p>
                </div>
              </template>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
    <template v-if="filtered_items.length === 0">
      <div class="row">
        <template v-if="modelValue.length > 0">
          <div class="col-12">
            <slot name="no-match">
              <div class="alert alert-warning m-0">
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-warning">Ops...</h4>
                  <span
                    >Your search for "<b>{{ search }}</b
                    >" returned no results.</span
                  >
                </div>
              </div>
            </slot>
          </div>
        </template>
        <template v-else>
          <div class="col-12">
            <slot name="no-data">
              <div class="alert alert-info m-0">
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-info">Hmm.</h4>
                  <span>There is no data to show.</span>
                </div>
              </div>
            </slot>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DataTables',
  emits: {
    'update:modelValue': {
      type: Array,
    },
    'by-click-on-tr': {
      type: Object,
    },
    selecteds: {
      type: Array,
    },
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    paginate: {
      type: Boolean,
      default: false,
    },
    tr_button: {
      type: Boolean,
      default: false,
    },
    excel_filter: {
      type: Boolean,
      default: false,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    row_color: {
      type: Boolean,
      default: false,
    },
    checkbox_value: {
      type: String,
      default: 'id',
    },
    scroll_update: {
      type: Boolean,
      default: false,
    },
    disable_body_slot: {
      type: Boolean,
      default: false,
    },
    table_class: {
      type: String,
      default: 'gy-7 gs-7'
    }
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
      items: [],
      sortedBy: {
        column: '',
        direction: '',
        icon: '',
      },
      paginateSetup: {
        page: null,
        per_page: null,
        prePage: null,
        nextPage: null,
        total: null,
        totalPages: null,
        data: [],
      },
      selected_checkboxes: [],
      check_all: false,
      excel_filter_column: null,
      excel_filter_search: '',
      excel_filtered_items: {},
      first_column: null,
    }
  },
  computed: {
    pagesIndex() {
      let i = 1

      let index = []

      while (this.paginateSetup.totalPages >= i) {
        if (
          i >= this.paginateSetup.page - 3 &&
          i <= this.paginateSetup.page + 3
        ) {
          index.push(i)
        }

        i++
      }

      if (index[0] > 2) {
        index = [1, null, ...index]
      }

      if (index[index.length - 1] < this.paginateSetup.totalPages) {
        index = [...index, null, this.paginateSetup.totalPages]
      }

      return index
    },
    excelFilterItems() {
      const search = window.helpers.RemoveAccent(
        this.excel_filter_search.toString().toLowerCase()
      )

      if (this.excel_filter_column) {
        let column = this.excel_filter_column.split('-')

        column = this.headers[column[1]]

        let columns = this.items
          .filter((item) => {
            let match = true

            let pos = 0

            if (this.first_column != this.excel_filter_column) {
              match = false
              //PERCORRE OS HEADERS
              while (this.headers.length > pos) {
                const value = window._.get(item, this.headers[pos].value)

                match = true

                if (this.excel_filter) {
                  //SE HÁ ALGUM DADO SELECIONADO NESTA COLUNA
                  if (
                    this.excel_filtered_items[`filter-${pos}`] &&
                    this.excel_filtered_items[`filter-${pos}`].length > 0
                  ) {
                    const entries = this.excel_filtered_items[`filter-${pos}`]

                    if (entries.indexOf(value) === -1) {
                      match = false

                      break
                    }
                  }
                }

                pos++
              }
            }

            return match
          })
          .map((i) => {
            const item = window._.get(i, column.value)

            return {
              label: item && item.label ? item.label : item,
              value: item && item.value ? item.value : item,
            }
          })

        return window._.uniqBy(columns, 'value').filter((c) => {
          if (search) {
            if (typeof c === 'object' && c.label) {
              let item = window.helpers.RemoveAccent(
                c.label.toString().toLowerCase()
              )

              if (item.indexOf(search) > -1) {
                return true
              }
            }

            return false
          }

          return true
        })
      }

      return []
    },
    excel_filter_column_object() {
      if (this.excel_filter_column) {
        let column = this.excel_filter_column.split('-')

        return this.headers[column[1]]
      }

      return {}
    },
    filtered_items() {
      const search = window.helpers.RemoveAccent(
        this.search.toString().toLowerCase()
      )

      //BUSCA
      let items = this.items.filter((item) => {
        let match = false

        let pos = 0

        //PERCORRE OS HEADERS
        while (this.headers.length > pos) {
          const value = window._.get(item, this.headers[pos].value)

          match = true

          if (this.excel_filter) {
            //SE HÁ ALGUM DADO SELECIONADO NESTA COLUNA
            if (
              this.excel_filtered_items[`filter-${pos}`] &&
              this.excel_filtered_items[`filter-${pos}`].length > 0
            ) {
              const entries = this.excel_filtered_items[`filter-${pos}`]

              if (entries.indexOf(value.value ?? value) === -1) {
                match = false

                break
              }
            }
          }

          if (search && match) {
            //UPPERCASE E REMOVE ACENTOS
            let i = window.helpers.RemoveAccent(
              (value ? value.value ?? value : '').toString().toLowerCase()
            )

            if (i.includes(search)) {
              match = true

              break
            }

            match = false
          }

          pos++
        }

        return match
      })

      //PAGINATION
      if (this.paginate) {
        let itemsPerPage = this.itemsPerPage

        let numberOfPages = Math.ceil(items.length / itemsPerPage)

        let paginate = this.Paginate(items, this.currentPage, itemsPerPage)

        items = paginate.data
        this.paginateSetup = paginate
      }

      return items
    },
  },
  watch: {
    excel_filtered_items: {
      handler(excel_filtered_items) {
        let column = null
        let pos = 0

        //PERCORRE OS HEADERS
        while (this.headers.length > pos) {
          if (
            excel_filtered_items[`filter-${pos}`] &&
            excel_filtered_items[`filter-${pos}`].length
          ) {
            column = `filter-${pos}`
          }

          pos++
        }

        this.first_column = !column ? null : this.first_column ?? column
      },
      immediate: true,
      deep: true,
    },
    excel_filter_column(val) {
      if (val) {
        window.jQuery('#excel-filter').modal('show')
      } else {
        window.jQuery('#excel-filter').modal('hide')
      }
    },
    modelValue: {
      handler(val) {
        this.items = val
        this.selected_checkboxes = []
        this.check_all = false
      },
      immediate: true,
      deep: true,
    },
    headers: {
      handler(val) {
        if (val.length > 0) {
          val.forEach((v, index) => {
            this.excel_filtered_items[`filter-${index}`] = []
          })
        }
      },
      deep: true,
      immediate: true,
    },
    itemsPerPage(val) {
      this.currentPage = 1
    },
    check_all: {
      handler(val) {
        if (val) {
          this.selected_checkboxes = this.items.map((item) => {
            return item[this.checkbox_value]
          })
        } else {
          this.selected_checkboxes = []
        }

        this.EmitCheckboxes()
      },
    },
  },
  methods: {
    OpenExcelFilter(index) {
      this.excel_filter_search = ''

      if (this.excel_filter_column == `filter-${index}`) {
        this.excel_filter_column = null
      } else {
        this.excel_filter_column = `filter-${index}`
      }
    },
    EmitCheckboxes() {
      this.$emit('selecteds', this.selected_checkboxes)
    },
    ByClickOnTR(item) {
      if (this.tr_button) {
        this.$emit('by-click-on-tr', item)
      }
    },
    GetTDContent(item, header) {
      return window._.get(item, header.value)
    },
    OrderItems(key, order = 'ASC') {
      const type = key.type
      const value = key.value

      return (a, b) => {
        let varA = window._.get(a, value)
        let varB = window._.get(b, value)

        switch (type) {
          case String:
            if (!varA) {
              return order === 'DESC' ? 1 : -1
            }

            if (!varB) {
              return order === 'DESC' ? -1 : 1
            }

            varA =
              typeof varA === 'object'
                ? varA.value.toString().toUpperCase()
                : varA.toString().toUpperCase()
            varB =
              typeof varB === 'object'
                ? varB.value.toString().toUpperCase()
                : varB.toString().toUpperCase()

            varA = window.helpers.RemoveAccent(varA)
            varB = window.helpers.RemoveAccent(varB)

            if (varA > varB) {
              return order === 'DESC' ? -1 : 1
            } else if (varA < varB) {
              return order === 'DESC' ? 1 : -1
            }

            return 0

          case Boolean:
            varA = typeof varA === 'object' ? varA.value : varA
            varB = typeof varB === 'object' ? varB.value : varB

            if (varA && !varB) {
              return order === 'DESC' ? 1 : -1
            } else if (!varA && varB) {
              return order === 'DESC' ? -1 : 1
            }

            return 0

          case Number:
            varA = parseFloat(typeof varA === 'object' ? varA.value : varA)
            varB = parseFloat(typeof varB === 'object' ? varB.value : varB)

            if (varA > varB) {
              return order === 'DESC' ? -1 : 1
            } else if (varA < varB) {
              return order === 'DESC' ? 1 : -1
            }

            return 0

          case Date:
            if (!varA) {
              return order === 'DESC' ? 1 : -1
            }

            if (!varB) {
              return order === 'DESC' ? -1 : 1
            }

            varA = new Date(typeof varA === 'object' ? varA.value : varA)
            varB = new Date(typeof varB === 'object' ? varB.value : varB)

            if (varA > varB) {
              return order === 'DESC' ? -1 : 1
            } else if (varA < varB) {
              return order === 'DESC' ? 1 : -1
            }

            return 0
          default:
            return 0
        }
      }
    },
    Paginate(items, page = 1, per_page = 5) {
      page = page || 1
      per_page = per_page || 5
      let offset = (page - 1) * per_page
      let paginatedItems = items.slice(offset).slice(0, per_page)
      let totalPages = Math.ceil(items.length / per_page)

      return {
        page: page,
        per_page: per_page,
        initItem: offset + 1,
        endItem: offset + paginatedItems.length,
        prePage: page - 1 ? page - 1 : null,
        nextPage: totalPages > page ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
        data: paginatedItems,
      }
    },
    WalkPaginate(mode) {
      switch (mode) {
        case 'prev':
          this.currentPage = this.currentPage <= 1 ? 1 : this.currentPage - 1
          break
        case 'next':
          this.currentPage =
            this.currentPage < this.paginateSetup.totalPages
              ? this.currentPage + 1
              : this.currentPage - this.paginateSetup.totalPages
          break
        default:
          //direct page
          this.currentPage = mode
          break
      }
    },
    SortData(header) {
      if (header.value && header.sortable && header.type) {
        if (this.sortedBy.column != header.value) {
          this.sortedBy.column = header.value
          this.sortedBy.direction = ''
          this.sortedBy.icon = ''
        }

        let items = [...this.items]

        switch (this.sortedBy.direction) {
          case 'ASC':
            this.sortedBy.direction = 'DESC'
            this.sortedBy.icon = 'chevron-down'

            items.sort(this.OrderItems(header, 'DESC'))

            this.items = [...items]

            break
          case 'DESC':
            this.sortedBy.column = ''
            this.sortedBy.direction = ''
            this.sortedBy.icon = ''

            items.sort(this.OrderItems(this.headers[0], 'ASC'))

            this.items = [...items]

            break
          default:
            this.sortedBy.direction = 'ASC'
            this.sortedBy.icon = 'chevron-up'

            items.sort(this.OrderItems(header, 'ASC'))

            this.items = [...items]

            break
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.base-table {
  .th-content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    &:hover {
      .filter {
        opacity: 1;
      }
    }

    .filter {
      opacity: 0;
      transition: all 0.2s linear;

      &.active {
        opacity: 1;
      }

      a {
        width: 2rem;
        height: 2rem;
        border-radius: 0.65rem;
      }
    }

    .sort-icon {
      margin: 0 0.5rem;
    }
  }
}
</style>
