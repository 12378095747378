<template>
  <div
    class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold"
  >
    <template v-if="navs.length > 0">
      <RecursiveMenu v-for="nav in navs" :key="nav.id" :nav="nav" :index="1" />
    </template>
    <template v-else> loading... </template>
  </div>
</template>

<script>
import RecursiveMenu from './RecursiveMenu'
export default {
  name: 'Menu',
  components: {
    RecursiveMenu,
  },
  data() {
    return {
      navs: [],
    }
  },
  watch: {
    $route(to, from) {
      this.feedNavs()
    },
  },
  beforeMount() {
    this.feedNavs()
  },
  methods: {
    feedNavs() {
      this.navs = this.recursiveNavs(this.$router.options.routes)
    },
    recursiveNavs(routes = []) {
      let parsedRoutes = []

      if (routes.length > 0) {
        let currentRoute = this.$router.currentRoute

        currentRoute =
          currentRoute.value.meta.alternativeMenu ?? currentRoute.value.name

        for (let [index, route] of Object.entries(routes)) {
          if (route.meta && route.meta.onMenu) {
            const navId = `nav-${route.meta.title
              .toString()
              .replace(/\s/g, '')
              .toLowerCase()}-${index}`

            let nav = {
              id: navId,
              name: route.meta.title,
              route: route.name ?? null,
              active: false,
            }

            if (route.children && route.children.length > 0) {
              let children = this.recursiveNavs(route.children)

              nav.active = children.some((c) => c.route == currentRoute)

              nav.children = children
            } else {
              nav.active = nav.route == currentRoute
            }

            parsedRoutes.push(nav)
          }
        }
      }

      return parsedRoutes
    },
  },
}
</script>

<style></style>
