import Internal from '@layouts/Internal'

export default [
  {
    path: '/overview',
    component: () => import('@layouts/components/EmptyComponent'),
    meta: {
      onMenu: true,
      onBreadcrumb: false,
      title: 'Dashboard',
    },
    children: [
      {
        name: 'DashboardIndex',
        path: '/overview',
        component: () => import('@modules/dashboard/views/DashboardIndex'),
        meta: {
          middlewareAuth: true,
          onBreadcrumb: true,
          title: 'Home',
          onMenu: true,
          layout: Internal,
        },
      },
      {
        path: '',
        redirect: {
          name: 'DashboardIndex',
        },
      },
    ],
  },
]
