import CryptoJS from 'crypto-js'
import Swal from "sweetalert2/src/sweetalert2";

class Helpers {
	constructor () {}

	UniqueToken()
	{
		return this.MD5(new Date())
			.replace(/[^A-Za-z0-9\s!?]/g, '')
	}

	MD5(content)
	{
		const secret 	= process.env.VUE_APP_SECRET_KEY
		content 		= JSON.stringify(content)

		return CryptoJS.AES.encrypt(content, secret)
			.toString()
	}

	DecryptMD5(encrypted)
	{
		const secret 	= process.env.VUE_APP_SECRET_KEY
		const bytes 	= CryptoJS.AES.decrypt(encrypted, secret)

		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
	}

	ValidateCPF (cpf) 
	{
		cpf 		= cpf.replace(/[^\d]+/g,'');

		if(cpf == '')
		{
			return false;
		} 

		let num, digit, sum, i, res, same_digits;

		same_digits = 1;

		if (cpf.length < 11)
		{
			return false;
		}

		for (i = 0; i < cpf.length - 1; i++)
		{
			if (cpf.charAt(i) != cpf.charAt(i + 1))
			{
				same_digits = 0;
				break;
			}
		}

		if (!same_digits)
		{
			num 		= cpf.substring(0,9);
			digit 		= cpf.substring(9);
			sum 		= 0;

			for (i = 10; i > 1; i--)
			{
				sum += num.charAt(10 - i) * i;
			}

			res 		= sum % 11 < 2 ? 0 : 11 - sum % 11;

			if (res != digit.charAt(0))
			{
				return false;
			}

			num 		= cpf.substring(0,10);
			sum 		= 0;

			for (i = 11; i > 1; i--)
			{
				sum += num.charAt(11 - i) * i;
			}

			res 	= sum % 11 < 2 ? 0 : 11 - sum % 11;

			if (res != digit.charAt(1))
			{
				return false;
			}

			return true;

		}else{

			return false;
		}
	}

	ValidateCNPJ (cnpj)
	{
		cnpj 		= cnpj.replace(/[^\d]+/g,'');

		if(cnpj == '')
		{
			return false;
		} 

		if (cnpj.length != 14)
		{
			return false;
		}

		if (cnpj == "00000000000000" || 
			cnpj == "11111111111111" || 
			cnpj == "22222222222222" || 
			cnpj == "33333333333333" || 
			cnpj == "44444444444444" || 
			cnpj == "55555555555555" || 
			cnpj == "66666666666666" || 
			cnpj == "77777777777777" || 
			cnpj == "88888888888888" || 
			cnpj == "99999999999999")
		{
			return false;
		}

		let i;
		let size 		= cnpj.length - 2;
		let num 		= cnpj.substring(0,size);
		let digit 		= cnpj.substring(size);
		let sum 		= 0;
		let pos 		= size - 7;

		for (i = size; i >= 1; i--) 
		{
			sum 		+= num.charAt(size - i) * pos--;

			if (pos < 2)
			{
				pos 	= 9;
			}
		}

		let res 			= sum % 11 < 2 ? 0 : 11 - sum % 11;

		if (res != digit.charAt(0))
		{
			return false;
		}
		
		size 			= size + 1;
		num 			= cnpj.substring(0,size);
		sum 			= 0;
		pos 			= size - 7;

		for (i = size; i >= 1; i--) 
		{
			sum 		+= num.charAt(size - i) * pos--;
			if (pos < 2)
			{
				pos 		= 9;
			}
		}

		res 			= sum % 11 < 2 ? 0 : 11 - sum % 11;

		if (res != digit.charAt(1))
		{
			return false;
		}
		
		
		return true;
	}

	RemoveAccent (str_to_replace)
	{

		let str_with_accent 	= "áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ"
		let str_without_accent 	= "aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC"
		let newest_str 			= ""

		for (let i = 0; i < str_to_replace.length; i++) 
		{
			if (str_with_accent.indexOf(str_to_replace.charAt(i)) != -1) {
				newest_str += str_without_accent.substr(str_with_accent.search(str_to_replace.substr(i, 1)), 1);
			} else {
				newest_str += str_to_replace.substr(i, 1);
			}
		}

		return newest_str;
	}

	Slugify(string)
	{

		let slugged_string 		= string.toLowerCase()
			.replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
			.replace(/[èÈéÉêÊëË]+/g, 'e')
			.replace(/[ìÌíÍîÎïÏ]+/g, 'i')
			.replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
			.replace(/[ùÙúÚûÛüÜ]+/g, 'u')
			.replace(/[ýÝÿŸ]+/g, 'y')
			.replace(/[ñÑ]+/g, 'n')
			.replace(/[çÇ]+/g, 'c')
			.replace(/[ß]+/g, 'ss')
			.replace(/[Ææ]+/g, 'ae')
			.replace(/[Øøœ]+/g, 'oe')
			.replace(/[%]+/g, 'pct')
			.replace(/\s+/g, '-')
			//.replace(/[^\w\-]+/g, '')
			//.replace(/\-\-+/g, '-')
			.replace(/^-+/g, '')
			.replace(/-+$/g, '');

		return slugged_string
	}

	SecondsToTime(seconds)
	{
		let secs 	= Math.round(seconds);
		let h 		= Math.floor(secs / (60 * 60));

		let divisor_for_minutes = secs % (60 * 60);
		let m 		= Math.floor(divisor_for_minutes / 60);

		let divisor_for_seconds = divisor_for_minutes % 60;
		let s 		= Math.ceil(divisor_for_seconds);
		
		return h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0') + ':' + s.toString().padStart(2, '0')
	}

	SlugifyWithDiff(string)
	{
		let date 				= new Date()
		let diff 				= String(date.getMinutes()) + String(date.getHours()) + String(date.getSeconds()) + String(date.getMonth())

		let slugged_string 		= this.Slugify(string)

		return diff + '-' + slugged_string
	}

	Base64ToFile(base64, mime, name) 
	{
		mime 			= mime || ''
		let sliceSize 	= 1024

		base64 			= base64.replace('data:image/png;base64,','');
		base64 			= base64.replace('data:image/webp;base64,','');
		base64 			= base64.replace('data:image/jpg;base64,','');
		base64 			= base64.replace('data:image/jpeg;base64,','');

		let byteChars 	= window.atob(base64)
		let byteArrays 	= []

		for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) 
		{
			let slice 			= byteChars.slice(offset, offset + sliceSize)

			let byteNumbers 	= new Array(slice.length)

			for (let i = 0; i < slice.length; i++) 
			{
				byteNumbers[i] 			= slice.charCodeAt(i)
			}

			let byteArray 		= new Uint8Array(byteNumbers)

			byteArrays.push(byteArray)
		}

		return new File(byteArrays , name , { type: mime })
	}

	ParseDate(string)
	{
		if(!string)
		{
			return ''
		}

		if(string.length == 27)
		{
			string 	= this.ParseISODate(string)
		}

		let split 	= string.split(' ')

		let date    = split[0].split('-')
		let time    = split[1].split(':')

		return `${date[2]}/${date[1]}/${date[0]} ás ${time[0]}:${time[1]}`
	}

	ParseISODate(date)
	{
		let data 	= new Date(date)

		return `${data.getFullYear()}-${(data.getMonth() + 1).toString().padStart(2,'0')}-${data.getDate().toString().padStart(2,'0')} ${data.getHours().toString().padStart(2,'0')}:${data.getMinutes().toString().padStart(2,'0')}:${data.getSeconds().toString().padStart(2,'0')}`
	}

	ParseTime(date)
	{
		let data 	= new Date(date)

		return `${data.getHours().toString().padStart(2,'0')}:${data.getMinutes().toString().padStart(2,'0')}`
	}

    ParseCPF(cpf)
    {
    	return cpf.replace(/[^0-9]/g, "").replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4")
    }

    ParseCNPJ(cnpj)
    {
    	return cnpj.replace(/[^0-9]/g, "").replace(/^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/, "$1.$2.$3/$4-$5")
    }

    GetDateNow()
    {
    	let date 				= new Date()
    	
    	return date.getDate().toString().padStart(2,'0') + '/' + (date.getMonth() + 1).toString().padStart(2,'0') + '/' + date.getFullYear()
    }

    FormatDateObj(date)
    {	
    	if(typeof date !== 'object')
    	{
    		date 		= date.split('-')

    		date 		= new Date(date[0],date[1],date[2])
    	}

    	return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2,'0')}-${date.getDate().toString().padStart(2,'0')}`
    }

    FormatDate(date) 
	{
        if (!date) return null

        const [year, month, day] = date.split('-')

        return `${day}/${month}/${year}`
    }

    FormatMoney(float)
    {
    	let formato 	= { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' }

		let money 		= parseFloat(float).toLocaleString('pt-BR',formato)

		return money
    }

	UniqueID()
	{
		return Math.floor(Math.random() * Date.now())
	}

	HumanFileSize (bytes, si) 
	{
		let thresh 		= si ? 1000 : 1024
		if (Math.abs(bytes) < thresh) {
		  return bytes + ' B'
		}
		let units 		= si
		  ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

		let u 			= -1

		do {
		  	bytes /= thresh
		  	++u
		} while (Math.abs(bytes) >= thresh && u < units.length - 1)
		
		return bytes.toFixed(1) + ' ' + units[u]
	}

	IsIosSafari () 
	{
    	return navigator.userAgent.match(/iP(od|hone|ad)/) &&
	      	navigator.userAgent.match(/AppleWebKit/) &&
	      	!navigator.userAgent.match(/(Cr|Fx|OP)iOS/)
  	}

  	ErrorMsg(error, icon = 'error', timer = 8000)
	{
		if ( error instanceof String) {
			Swal.fire({
				icon: icon,
				text: error,
				toast: true,
				position: 'top',
				timer: timer
			})
			return
		}

		if ( error.message == 'validation_errors' && error.validation )
		{

			let finalMessage = [];

			for (const [key, value] of Object.entries(error.validation)) {
				finalMessage.push(value)
			}

			Swal.fire({
				icon: 'error',
				text: finalMessage.join(' '),
				toast: true,
				position: 'top',
				timer
			})

			return

		}

		if ( error.message && error.message == 'unknown_error' && error.error ) {
			Swal.fire({
				icon: 'error',
				text: error.error,
				toast: true,
				position: 'top',
				timer
			})
			return
		}

		Swal.fire({
			icon: 'error',
			text: (error.error ?? error.message) ?? error,
			toast: true,
			position: 'top',
			timer
		})

	}

	SuccessMsg(msg, icon = 'success', timer = 4000)
	{
		Swal.fire({
			icon: icon,
			text: msg,
			toast: true,
			position: 'top',
			timer: timer
		})
	}

	ExtractValidationErrors(data) {
		let finalMessage = [];

		for (const [key, value] of Object.entries(data.validation)) {
			finalMessage.push(value)
		}

		return finalMessage.join(' ')
	}
}

export default Helpers;